//import 'babel-polyfill';
import { Reducer } from 'redux';
import { IApplicationState } from './index';
import * as fromPageScroll from './PageScroll';
import * as fromSignalR from 'store/middleware/signalR';

import { createSelector } from 'reselect';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export type IInputTypes = 'inputShortText' | 'inputLongText' | 'radioButtons' | 'checkboxes';

export interface IFormValueShortTextProps {
  value: string;
}

export interface IFormValueLongTextProps {
  value: string;
}

export interface IFormValueRadioButtonsProps {
  value: string;
}

export interface IFormValueCheckboxesProps {
  value: string[];
}

export type IFormValueOptions = IFormValueShortTextProps | IFormValueLongTextProps | IFormValueRadioButtonsProps | IFormValueCheckboxesProps;

export type IMessageFrom = 'qore' | 'friend';

export interface IFormValue {
  id: string;
  title: string;
  type: IInputTypes;
  props: IFormValueOptions;
}

export interface IMessage {
  id: string;
  from: IMessageFrom;
  message: string;
  allowHtml: boolean;
  time: string;
}

interface IChatModalState {
  triggerActive: boolean;
  windowOpen: boolean;
  chatId: string;
  chatStarted: boolean;
  notificationDot: boolean;
  triggerWidth: number;
  messages: IMessage[];
  formValues: IFormValue[];
  currentScriptStepId: string;
  nextScriptStepId: string;
  currentUserInputId: string;
  userInputVisible: boolean;
  active: boolean; // This marks the active chat modal window as the one that controls script step interactions
}

export interface IInterfaceOverlayState {
  chatModal: IChatModalState;
  backToWorkTriggerActive: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export enum keys {
  ENTER_CONTACT_TRIGGER = 'ENTER_CONTACT_TRIGGER',
  LEAVE_CONTACT_TRIGGER = 'LEAVE_CONTACT_TRIGGER',
  OPEN_CHAT_MODAL = 'OPEN_CHAT_MODAL',
  CLOSE_CHAT_MODAL = 'CLOSE_CHAT_MODAL',
  SET_CHAT_ID = 'SET_CHAT_ID',
  MARK_CHAT_AS_STARTED = 'MARK_CHAT_AS_STARTED',
  LOAD_CHAT_MESSAGES = 'LOAD_CHAT_MESSAGES',
  RECEIVE_CHAT_MESSAGE = 'RECEIVE_CHAT_MESSAGE',
  LOAD_CHAT_FORM_VALUES = 'LOAD_CHAT_FORM_VALUES',
  RECEIVE_FORM_VALUE = 'RECEIVE_FORM_VALUE',
  RESET_FORM_VALUES = 'RESET_FORM_VALUES',
  UPDATE_CHAT_CURRENTSCRIPTSTEPID = 'UPDATE_CHAT_CURRENTSCRIPTSTEPID',
  UPDATE_CHAT_NEXTSCRIPTSTEPID = 'UPDATE_CHAT_NEXTSCRIPTSTEPID',
  UPDATE_CHAT_CURRENTUSERINPUTID = 'UPDATE_CHAT_CURRENTUSERINPUTID',
  UPDATE_CHAT_USERINPUTVISIBILITY = 'UPDATE_CHAT_USERINPUTVISIBILITY',
  ADD_CHAT_NOTIFICATION_DOT = 'ADD_CHAT_NOTIFICATION_DOT',
  REMOVE_CHAT_NOTIFICATION_DOT = 'REMOVE_CHAT_NOTIFICATION_DOT',
  UPDATE_CHAT_TRIGGER_WIDTH = 'UPDATE_CHAT_TRIGGER_WIDTH',
  SET_CHAT_ACTIVE_STATE = 'SET_CHAT_ACTIVE_STATE',
  ENTER_BACKTOWORK_TRIGGER = 'ENTER_BACKTOWORK_TRIGGER',
  LEAVE_BACKTOWORK_TRIGGER = 'LEAVE_BACKTOWORK_TRIGGER',
}

interface IEnterContactTriggerAction { type: keys.ENTER_CONTACT_TRIGGER; }
interface ILeaveContactTriggerAction { type: keys.LEAVE_CONTACT_TRIGGER; }
interface IOpenChatModalAction { type: keys.OPEN_CHAT_MODAL; }
interface ICloseChatModalAction { type: keys.CLOSE_CHAT_MODAL; }
interface ISetChatIdAction { type: keys.SET_CHAT_ID; id: string; }
interface IMarkChatAsStartedAction {
  type: keys.MARK_CHAT_AS_STARTED;
  isCaller: boolean;
}
interface ILoadChatMessagesAction { type: keys.LOAD_CHAT_MESSAGES; messages: IMessage[]; }
interface IReceiveChatMessageAction {
  type: keys.RECEIVE_CHAT_MESSAGE;
  message: IMessage;
  isCaller: boolean;
}
interface ILoadFormValuesAction { type: keys.LOAD_CHAT_FORM_VALUES; formValues: IFormValue[]; }
interface IReceiveFormValueAction {
  type: keys.RECEIVE_FORM_VALUE;
  formValue: IFormValue;
  isCaller: boolean;
}
interface IResetFormValuesAction { type: keys.RESET_FORM_VALUES; isCaller: boolean; }
interface IUpdateChatCurrentScriptStepIdAction {
  type: keys.UPDATE_CHAT_CURRENTSCRIPTSTEPID;
  id: string;
  isCaller: boolean;
}
interface IUpdateChatNextScriptStepIdAction {
  type: keys.UPDATE_CHAT_NEXTSCRIPTSTEPID;
  id: string;
  isCaller: boolean;
}
interface IUpdateChatCurrentUserInputIdAction {
  type: keys.UPDATE_CHAT_CURRENTUSERINPUTID;
  id: string;
  isCaller: boolean;
}
interface IUpdateChatUserInputVisibilityAction {
  type: keys.UPDATE_CHAT_USERINPUTVISIBILITY;
  visible: boolean;
  isCaller: boolean;
}
interface IAddChatNotificationDotAction { type: keys.ADD_CHAT_NOTIFICATION_DOT; }
interface IRemoveChatNotificationDotAction { type: keys.REMOVE_CHAT_NOTIFICATION_DOT; }
interface IUpdateChatTriggerWidthAction { type: keys.UPDATE_CHAT_TRIGGER_WIDTH; width: number; }
interface ISetChatActiveStateAction { type: keys.SET_CHAT_ACTIVE_STATE; isCaller: boolean; }
interface IEnterBackToWorkTriggerAction { type: keys.ENTER_BACKTOWORK_TRIGGER; }
interface ILeaveBackToWorkTriggerAction { type: keys.LEAVE_BACKTOWORK_TRIGGER; }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type IKnownAction =
  IEnterContactTriggerAction
  | ILeaveContactTriggerAction
  | IOpenChatModalAction
  | ICloseChatModalAction
  | ISetChatIdAction
  | IMarkChatAsStartedAction
  | ILoadChatMessagesAction
  | IReceiveChatMessageAction
  | ILoadFormValuesAction
  | IReceiveFormValueAction
  | IResetFormValuesAction
  | IUpdateChatCurrentScriptStepIdAction
  | IUpdateChatNextScriptStepIdAction
  | IUpdateChatCurrentUserInputIdAction
  | IUpdateChatUserInputVisibilityAction
  | IAddChatNotificationDotAction
  | IRemoveChatNotificationDotAction
  | IUpdateChatTriggerWidthAction
  | ISetChatActiveStateAction
  | IEnterBackToWorkTriggerAction
  | ILeaveBackToWorkTriggerAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
// We are using them to fire off our events that will be handled by the root sagas and delegated consequently

export const actionCreators = {
  enterContactTriggerAction: () => dispatch => {
    dispatch({ type: keys.ENTER_CONTACT_TRIGGER });
  },
  leaveContactTriggerAction: () => dispatch => {
    dispatch({ type: keys.LEAVE_CONTACT_TRIGGER });
  },
  openChatModalAction: () => dispatch => {
    dispatch(fromPageScroll.actionCreators.disablePageScroll());
    dispatch({ type: keys.OPEN_CHAT_MODAL });
  },
  closeChatModalAction: () => dispatch => {
    dispatch(fromPageScroll.actionCreators.restorePageScroll());
    dispatch({ type: keys.CLOSE_CHAT_MODAL });
  },
  setChatActiveState: (isCaller: boolean = true) => dispatch => {
    dispatch({ type: keys.SET_CHAT_ACTIVE_STATE, isCaller });
  },
  markChatAsActive: () => dispatch => {
    dispatch({ type: keys.SET_CHAT_ACTIVE_STATE, isCaller: true });
  },
  setChatId: (id: string) => dispatch => {
    dispatch({ type: keys.SET_CHAT_ID, id });
  },
  startChat: () => dispatch => {
    dispatch(actionCreators.markChatAsStarted());
    dispatch(fromSignalR.actionCreators.startChat());
  },
  markChatAsStarted: (isCaller: boolean = true) => dispatch => {
    dispatch({ type: keys.MARK_CHAT_AS_STARTED, isCaller });
  },
  loadChatMessages: (messages: IMessage[]) => dispatch => {
    dispatch({
      type: keys.LOAD_CHAT_MESSAGES,
      messages,
    });
  },
  addChatMessage: (message: IMessage) => dispatch => {
    dispatch(actionCreators.receiveChatMessage(message));
    dispatch(fromSignalR.actionCreators.addChatMessage(message));
  },
  receiveChatMessage: (message: IMessage, isCaller: boolean = true) => dispatch => {
    dispatch({
      type: keys.RECEIVE_CHAT_MESSAGE,
      message,
      isCaller,
    });
  },
  loadFormValues: (formValues: IFormValue[]) => dispatch => {
    dispatch({
      type: keys.LOAD_CHAT_FORM_VALUES,
      formValues,
    });
  },
  addFormValue: (formValue: IFormValue) => dispatch => {
    dispatch(actionCreators.receiveFormValue(formValue));
    dispatch(fromSignalR.actionCreators.addFormValue(formValue));
  },
  receiveFormValue: (formValue: IFormValue, isCaller: boolean = true) => dispatch => {
    dispatch({
      type: keys.RECEIVE_FORM_VALUE,
      formValue,
      isCaller,
    });
  },
  resetFormValues: () => dispatch => {
    dispatch(actionCreators.receiveResetFormValues());
    dispatch(fromSignalR.actionCreators.resetFormValues());
  },
  receiveResetFormValues: (isCaller: boolean = true) => dispatch => {
    dispatch({ type: keys.RESET_FORM_VALUES, isCaller });
  },
  updateCurrentScriptStepId: (id: string, isCaller: boolean = true) => dispatch => {
    dispatch(actionCreators.receiveCurrentScriptStepId(id, isCaller));
    dispatch(fromSignalR.actionCreators.updateCurrentScriptStepId(id));
  },
  receiveCurrentScriptStepId: (id: string, isCaller: boolean = true) => dispatch => {
    dispatch({
      type: keys.UPDATE_CHAT_CURRENTSCRIPTSTEPID,
      id,
      isCaller,
    });
  },
  updateNextScriptStepId: (id: string, isCaller: boolean = true) => dispatch => {
    dispatch(actionCreators.receiveNextScriptStepId(id, isCaller));
    dispatch(fromSignalR.actionCreators.updateNextScriptStepId(id));
  },
  receiveNextScriptStepId: (id: string, isCaller: boolean = true) => dispatch => {
    dispatch({
      type: keys.UPDATE_CHAT_NEXTSCRIPTSTEPID,
      id,
      isCaller,
    });
  },
  updateCurrentUserInputId: (id: string, isCaller: boolean = true) => dispatch => {
    dispatch(actionCreators.receiveCurrentUserInputId(id, isCaller));
    dispatch(fromSignalR.actionCreators.updateCurrentUserInputId(id));
  },
  receiveCurrentUserInputId: (id: string, isCaller: boolean = true) => dispatch => {
    dispatch({
      type: keys.UPDATE_CHAT_CURRENTUSERINPUTID,
      id,
      isCaller,
    });
  },
  updateUserInputVisibility: (visible: boolean) => dispatch => {
    dispatch(actionCreators.receiveUserInputVisibility(visible));
    dispatch(fromSignalR.actionCreators.updateUserInputVisibility(visible));
  },
  receiveUserInputVisibility: (visible: boolean, isCaller: boolean = true) => dispatch => {
    dispatch({
      type: keys.UPDATE_CHAT_USERINPUTVISIBILITY,
      visible,
      isCaller,
    });
  },
  addChatNotificationDot: () => dispatch => {
    dispatch({ type: keys.ADD_CHAT_NOTIFICATION_DOT });
  },
  removeChatNotificationDot: () => dispatch => {
    dispatch({ type: keys.REMOVE_CHAT_NOTIFICATION_DOT });
  },
  updateChatTriggerWidth: (width: number) => dispatch => {
    dispatch({
      type: keys.UPDATE_CHAT_TRIGGER_WIDTH,
      width,
    });
  },
  enterBackToWorkTriggerAction: () => dispatch => {
    dispatch({ type: keys.ENTER_BACKTOWORK_TRIGGER });
  },
  leaveBackToWorkTriggerAction: () => dispatch => {
    dispatch({ type: keys.LEAVE_BACKTOWORK_TRIGGER });
  },
};

// ----------------
// SAGA WATCHERS - Register all saga watchers here that will intercept all dispatched calls and delegate appropriately

// ----------------
// SAGA WORKERS - These are saga worker functions that are called when receiving saga dispatches. The saga watchers
// intercept dispatched calls and call the relevant saga functions when appropriate

// ----------------
// ROOT SAGA - Register all saga watchers into one root saga to be initialised in configureStore

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<IInterfaceOverlayState> = (state: IInterfaceOverlayState, action: IKnownAction) => {
  switch (action.type) {
    case keys.ENTER_CONTACT_TRIGGER:
      return {
        ...state,
        chatModal: {
          ...state.chatModal,
          triggerActive: true,
        },
      };
    case keys.LEAVE_CONTACT_TRIGGER:
      return {
        ...state,
        chatModal: {
          ...state.chatModal,
          triggerActive: false,
        },
      };
    case keys.OPEN_CHAT_MODAL:
      return {
        ...state,
        chatModal: {
          ...state.chatModal,
          triggerActive: false,
          windowOpen: true,
        },
      };
    case keys.CLOSE_CHAT_MODAL:
      return {
        ...state,
        chatModal: {
          ...state.chatModal,
          triggerActive: false,
          windowOpen: false,
        },
      };
    case keys.SET_CHAT_ID:
      return {
        ...state,
        chatModal: {
          ...state.chatModal,
          chatId: action.id,
        },
      };
    case keys.MARK_CHAT_AS_STARTED:
      return {
        ...state,
        chatModal: {
          ...state.chatModal,
          chatStarted: true,
          active: true,
        },
      };
    case keys.LOAD_CHAT_MESSAGES:
      return {
        ...state,
        chatModal: {
          ...state.chatModal,
          messages: action.messages,
          active: false,
        },
      };
    case keys.RECEIVE_CHAT_MESSAGE:
      const existingMessage = state.chatModal.messages.some(el => el.id === action.message.id);
      if (!existingMessage)
        return {
          ...state,
          chatModal: {
            ...state.chatModal,
            messages: [
              ...state.chatModal.messages,
              action.message,
            ],
            active: action.isCaller,
          },
        };
      else
        return { ...state };
    case keys.LOAD_CHAT_FORM_VALUES:
      return {
        ...state,
        chatModal: {
          ...state.chatModal,
          formValues: action.formValues,
          active: false,
        },
      };
    case keys.RECEIVE_FORM_VALUE:
      const existingFormValue = state.chatModal.formValues.some(el => el.id === action.formValue.id);
      if (!existingFormValue)
        return {
          ...state,
          chatModal: {
            ...state.chatModal,
            formValues: [
              ...state.chatModal.formValues,
              action.formValue,
            ],
            active: action.isCaller,
          },
        };
      else
        return { ...state };
    case keys.RESET_FORM_VALUES:
      return {
        ...state,
        chatModal: {
          ...state.chatModal,
          formValues: [],
          active: action.isCaller,
        },
      };
    case keys.UPDATE_CHAT_CURRENTSCRIPTSTEPID:
      return {
        ...state,
        chatModal: {
          ...state.chatModal,
          currentScriptStepId: action.id,
          active: action.isCaller,
        },
      };
    case keys.UPDATE_CHAT_NEXTSCRIPTSTEPID:
      return {
        ...state,
        chatModal: {
          ...state.chatModal,
          nextScriptStepId: action.id,
          active: action.isCaller,
        },
      };
    case keys.UPDATE_CHAT_CURRENTUSERINPUTID:
      return {
        ...state,
        chatModal: {
          ...state.chatModal,
          currentUserInputId: action.id,
          active: action.isCaller,
        },
      };
    case keys.UPDATE_CHAT_USERINPUTVISIBILITY:
      return {
        ...state,
        chatModal: {
          ...state.chatModal,
          userInputVisible: action.visible,
          active: action.isCaller,
        },
      };
    case keys.ADD_CHAT_NOTIFICATION_DOT:
      return {
        ...state,
        chatModal: {
          ...state.chatModal,
          notificationDot: true,
        },
      };
    case keys.REMOVE_CHAT_NOTIFICATION_DOT:
      return {
        ...state,
        chatModal: {
          ...state.chatModal,
          notificationDot: false,
        },
      };
    case keys.UPDATE_CHAT_TRIGGER_WIDTH:
      return {
        ...state,
        chatModal: {
          ...state.chatModal,
          triggerWidth: action.width,
        },
      };
    case keys.SET_CHAT_ACTIVE_STATE:
      return {
        ...state,
        chatModal: {
          ...state.chatModal,
          active: action.isCaller,
        },
      };
    case keys.ENTER_BACKTOWORK_TRIGGER:
      return {
        ...state,
        backToWorkTriggerActive: true,
      };
    case keys.LEAVE_BACKTOWORK_TRIGGER:
      return {
        ...state,
        backToWorkTriggerActive: false,
      };
    default:
      // The following line guarantees that every action in the KnownAction union has been covered by a case above
      const exhaustiveCheck: never = action;
  }

  // For unrecognized actions (or in cases where actions have no effect), must return the existing state
  //  (or default initial state if none was supplied)
  return state || {
    chatModal: {
      triggerActive: false,
      windowOpen: false,
      chatId: null,
      chatStarted: false,
      notificationDot: false,
      triggerWidth: 40,
      messages: [],
      formValues: [],
      currentScriptStepId: null,
      nextScriptStepId: null,
      currentUserInputId: null,
      userInputVisible: true,
      active: true,
    },
    backToWorkTriggerActive: false,
  };
};

// ----------------
// SELECTORS - These are functions exposed to UI components that will give them access to the associated store components.
// They only return the reference to the required state in the store, they don't change it.

const interfaceOverlaySelector = (state: IApplicationState) => state.interfaceOverlay;

const chatModalSelector = (state: IApplicationState) => interfaceOverlaySelector(state).chatModal;
export const getChatModalTrigger = createSelector(
  [chatModalSelector],
  chatModal => {
    //console.log("Output selector running: getContactTrigger");
    return chatModal.triggerActive;
  },
);
export const getChatModalWindow = createSelector(
  [chatModalSelector],
  chatModal => {
    //console.log("Output selector running: getContactTrigger");
    return chatModal.windowOpen;
  },
);
export const getChatModalId = createSelector(
  [chatModalSelector],
  chatModal => {
    //console.log("Output selector running: getContactTrigger");
    return chatModal.chatId;
  },
);
export const getChatModalStarted = createSelector(
  [chatModalSelector],
  chatModal => {
    //console.log("Output selector running: getContactTrigger");
    return chatModal.chatStarted;
  },
);
export const getChatModalMessages = createSelector(
  [chatModalSelector],
  chatModal => {
    //console.log("Output selector running: getContactTrigger");
    return chatModal.messages;
  },
);
export const getChatModalFormValues = createSelector(
  [chatModalSelector],
  chatModal => {
    //console.log("Output selector running: getContactTrigger");
    return chatModal.formValues;
  },
);
export const getChatModalCurrentScriptStepId = createSelector(
  [chatModalSelector],
  chatModal => {
    //console.log("Output selector running: getContactTrigger");
    return chatModal.currentScriptStepId;
  },
);
export const getChatModalNextScriptStepId = createSelector(
  [chatModalSelector],
  chatModal => {
    //console.log("Output selector running: getContactTrigger");
    return chatModal.nextScriptStepId;
  },
);
export const getChatModalCurrentUserInputId = createSelector(
  [chatModalSelector],
  chatModal => {
    //console.log("Output selector running: getContactTrigger");
    return chatModal.currentUserInputId;
  },
);
export const getChatModalUserInputVisible = createSelector(
  [chatModalSelector],
  chatModal => {
    //console.log("Output selector running: getContactTrigger");
    return chatModal.userInputVisible;
  },
);
export const getChatModalNotificationDot = createSelector(
  [chatModalSelector],
  chatModal => {
    //console.log("Output selector running: getContactTrigger");
    return chatModal.notificationDot;
  },
);
export const getChatTriggerWidth = createSelector(
  [chatModalSelector],
  chatModal => {
    //console.log("Output selector running: getContactTrigger");
    return chatModal.triggerWidth;
  },
);
export const getChatActive = createSelector(
  [chatModalSelector],
  chatModal => {
    //console.log("Output selector running: getContactTrigger");
    return chatModal.active;
  },
);

const backToWorkTriggerActiveSelector = (state: IApplicationState) => interfaceOverlaySelector(state).backToWorkTriggerActive;
export const getBackToWorkTrigger = createSelector(
  [backToWorkTriggerActiveSelector],
  backToWorkTriggerActive => {
    //console.log("Output selector running: getBackToWorkTrigger");
    return backToWorkTriggerActive;
  },
);
