import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks, BodyScrollOptions } from 'body-scroll-lock';

import * as fromPageScroll from 'store/PageScroll';

export const DisableScroll: React.FC<{}> = () => {
  const pageScrollEnabled = useSelector(fromPageScroll.getEnabled);

  const interfaceRef = useRef<HTMLDivElement>(null);

  const options: BodyScrollOptions = {
      reserveScrollBarGap: true,
  };

  useEffect(() => {
    if (pageScrollEnabled)
      enableBodyScroll(interfaceRef.current);
    else
      disableBodyScroll(interfaceRef.current, options);
  }, [pageScrollEnabled]);

  return <div ref={interfaceRef} />;
};
