import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useTimeout, useLatest } from 'utils/Index';
import { useDisabledScrollWidth } from 'hooks/useDisabledScrollWidth';
import { useSpring, useSprings, a } from 'react-spring';

import classNames from 'classnames';

import { Bar } from './Bar';

import * as fromInPageNavigation from 'store/InPageNavigation';

export type IBackgroundShade = 'light' | 'dark';

interface IProps {
  backgroundShade: IBackgroundShade;
}

const InPageNavigation: React.FC<IProps> = ({ backgroundShade = 'dark' as 'dark', ...props }) => {
  const [initialised, setInitialised] = useState(false);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Redux functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const visible = useSelector(fromInPageNavigation.getVisibility);
  const numberOfSections = useSelector(fromInPageNavigation.getTotalSectionsNumber);
  const navigationArray = useSelector(fromInPageNavigation.getNavigationArray);
  const currentSectionNumber = useSelector(fromInPageNavigation.getCurrentSectionNumber);
  const hoveringOverSectionNumber = useSelector(fromInPageNavigation.getHoverOverSectionNumber);
  const showSectionLabels = useSelector(fromInPageNavigation.getShowSectionLabels);
  const latestShowSectionLabels = useLatest(showSectionLabels);

  const dispatch = useDispatch();

  const updateHoveringSectionNumberAction = useCallback(
    (section: number) => dispatch(fromInPageNavigation.actionCreators.updateHoveringSectionNumberAction(section))
    , [dispatch]);

  const showSectionLabelsAction = useCallback(
    () => dispatch(fromInPageNavigation.actionCreators.showSectionLabelsAction())
    , [dispatch]);

  const hideSectionLabelsAction = useCallback(
    () => dispatch(fromInPageNavigation.actionCreators.hideSectionLabelsAction())
    , [dispatch]);

  const scrollToSectionNumberAction = useCallback(
    (section: number) => dispatch(fromInPageNavigation.actionCreators.scrollToSectionNumberAction(section))
    , [dispatch]);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Animate the nav bar in functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const [animateInSpring, setAnimateInSpring] = useSpring(() => ({ z: 0 }));
  useEffect(() => { setAnimateInSpring({ z: visible ? 1 : 0, immediate: !initialised }); }, [visible, initialised]);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Show or hide nav labels functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const [hideLabelsTimeout, setHideLabelsTimeout] = useState(false);
  useTimeout(() => {
    setHideLabelsTimeout(false);
    if (latestShowSectionLabels.current)
      hideSectionLabelsAction();
  }, hideLabelsTimeout ? 2000 : null);

  const handleMouseEnter = () => {
    setHideLabelsTimeout(false);
    if (!showSectionLabels)
      showSectionLabelsAction();
  };

  const handleMouseLeave = () => setHideLabelsTimeout(true);

  const updateHoveringSectionNumber = (section: number) => {
    if (hoveringOverSectionNumber !== section) {
      updateHoveringSectionNumberAction(section);
      handleMouseEnter();
    }
  };

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Scroll to section functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const scrollTo = sectionNumber => {
    if (sectionNumber > 0 && sectionNumber < navigationArray.length + 1)
      scrollToSectionNumberAction(sectionNumber);
  };

  const scrollToPreviousSection = () => { scrollTo(currentSectionNumber - 1); };
  const scrollToNextSection = () => { scrollTo(currentSectionNumber + 1); };

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Expand the current bar if scrolling function
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const [expandBar, setExpandBar] = useState(false);
  const [unexpandTimeout, setUnexpandTimeout] = useState(false);
  useTimeout(() => {
    setUnexpandTimeout(false);
    setExpandBar(false);
  }, unexpandTimeout ? 3000 : null);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Track scroll position and pass in y value to bar
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const [barPercentages, setBarPercentages] = useSprings(navigationArray.length, i => ({ y: 0 }));
  useEffect(() => {
    const handleScroll = () => {
      const y = window.scrollY;

      setExpandBar(true);
      setUnexpandTimeout(true);

      setBarPercentages(i => {
        const currentNavProps = navigationArray[i];
        let scrollProgress = (y - currentNavProps.scrollTop) / currentNavProps.height;

        if (scrollProgress < 0)
          scrollProgress = 0;
        else if (scrollProgress > 1)
          scrollProgress = 1;

        return {
          y: scrollProgress,
          immediate: (currentSectionNumber !== i + 1 && scrollProgress === 1) || !initialised ? true : false,
        };
      });
    };
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    if (!initialised)
      setInitialised(true);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [navigationArray, currentSectionNumber]);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Generate bars for page position functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const getBars = () => {
    const bars = [];
    if (navigationArray.length > 0) {
      navigationArray.map((navSection, i) => {
        const sectionNumber = i + 1;
        bars.push((
          <Bar
            key={i}
            sectionNumber={sectionNumber}
            currentSection={sectionNumber === currentSectionNumber}
            expand={sectionNumber === currentSectionNumber && expandBar}
            hovering={sectionNumber === hoveringOverSectionNumber}
            backgroundShade={backgroundShade}
            targetPosition={barPercentages[i].y}
            showLabel={showSectionLabels}
            label={navSection.title}
            updateHoveringSectionNumber={updateHoveringSectionNumber}
            onClick={() => scrollTo(sectionNumber)}
          />
        ));
      });
    }

    return bars;
  };

  const sbw = useDisabledScrollWidth();

  const wrapperStyles = {
    opacity: animateInSpring.z,
    // marginRight: sbw,
    marginRight: sbw.width.to(x => x),
  };

  return (
    <a.div
      className={classNames(
        'inPageNavigationWrapper',
        'backgroundShade-' + backgroundShade,
      )}
      style={wrapperStyles}
    >
      <div className='sectionNumber'>
        <div className='currentSection'>
          0{currentSectionNumber}
        </div>
        <div className='split' />
        <div className='totalSections'>
          0{numberOfSections}
        </div>
      </div>
      <div className='sectionBarsWrapper' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {getBars()}
      </div>
      <div className='sectionControls'>
        <div className='control previous' onClick={scrollToPreviousSection} />
        <div className='control next' onClick={scrollToNextSection} />
      </div>
    </a.div>
  );
};

export default InPageNavigation;
