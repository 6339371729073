//import 'babel-polyfill';
import { combineReducers, ReducersMapObject } from 'redux';
import { History } from 'history';
import { RouterState, connectRouter } from 'connected-react-router';
import { fork } from 'redux-saga/effects';
import * as fromWeatherForecasts from './WeatherForecasts';
import * as fromCounter from './Counter';
import * as fromInterfaceOverlay from './InterfaceOverlay';
import * as fromUserInterface from './UserInterface';
import * as fromViewport from './Viewport';
import * as fromPageScroll from './PageScroll';
import * as fromMainNavigation from './MainNavigation';
import * as fromInPageNavigation from './InPageNavigation';
import * as fromProjects from './Projects';

//import { reducer as responsiveReducer } from 'react-responsive-redux';

// The top-level state object
export interface IApplicationState {
  interfaceOverlay: fromInterfaceOverlay.IInterfaceOverlayState;
  userInterface: fromUserInterface.IUserInterfaceState;
  viewport: fromViewport.IViewportState;
  mainNavigation: fromMainNavigation.IMainNavigationState;
  inPageNavigation: fromInPageNavigation.IInPageNavigationState;
  pageScroll: fromPageScroll.IPageScrollState;
  counter: fromCounter.ICounterState;
  weatherForecasts: fromWeatherForecasts.IWeatherForecastsState;
  projects: fromProjects.IProjectsState;
  //responsive: any;
  router: RouterState;
}

// The top-level saga object that combines all the root sagas for all the separate ducks
export function* rootSaga() {
  yield [
    fork(fromCounter.rootSaga),
  ];
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  interfaceOverlay: fromInterfaceOverlay.reducer,
  userInterface: fromUserInterface.reducer,
  viewport: fromViewport.reducer,
  mainNavigation: fromMainNavigation.reducer,
  inPageNavigation: fromInPageNavigation.reducer,
  pageScroll: fromPageScroll.reducer,
  counter: fromCounter.reducer,
  weatherForecasts: fromWeatherForecasts.reducer,
  projects: fromProjects.reducer,
  //responsive: responsiveReducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export type IAppThunkAction<TAction> = (dispatch: (action: TAction) => void, getState: () => IApplicationState) => void;

export function createRootReducer(history: History, allReducers: ReducersMapObject) {
  //function createRootReducer(history: History, allReducers: ReducersMapObject) {
  return combineReducers({
    //return combineReducers<IApplicationState>({
    ...reducers,
    router: connectRouter(history),
    ...allReducers,
  });
}
