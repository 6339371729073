import React, { useState, useEffect } from 'react';
import { useSpring, SpringConfig } from 'react-spring';

export const useScrollTarget = (
  cancelAnimationOnManualScroll = true,
  onComplete: (restValues: object) => void = restValues => null,
  onStop: () => void = () => null,
) => {
  const [target, setTarget] = useState(null);

  const springConfig: SpringConfig = {
    mass: 1,
    tension: 270,
    friction: 36,
    clamp: true,
    precision: 0.01,
    velocity: 0,
    //duration: undefined,
    easing: t => t,
  };

  const scrollRest = (restValues: object) => {
    if (onComplete != null)
      onComplete(restValues);
  };

  const [, set, stop] = useSpring(() => ({
    y: -200,
    config: springConfig,
    onRest: restValues => scrollRest(restValues),
    default: true,
  }));

  useEffect(() => {
    if (target == null) {
      stop();
      if (onStop != null)
        onStop();
    }
    else
      set({
        y: target,
        reset: true,
        from: { y: window.scrollY },
        onChange: props => window.scroll(0, props.y as any),
      });
  }, [target]);

  useEffect(() => {
    if (cancelAnimationOnManualScroll) {
      // Setup event listeners on initial mount for cancelling scroll animations if the user is still scrolling
      const handleScroll = event => {
        setTarget(null);
      };

      window.addEventListener('wheel', handleScroll);

      return () => {
        window.removeEventListener('wheel', handleScroll);
      };
    }
  }, []);

  return setTarget;
};
