import { useCallback } from 'react';
import { useSpring } from 'react-spring';

export const useInertialSpring = (initialProps): [any, ({ config, ...to }: any) => void] => {
  const [props, set] = useSpring(() => initialProps);
  // const [props, set] = useSpring(() => ({ ...initialProps, onChange: () => onChange(true, null) }));

  const setInertia = useCallback(
    ({
      config = { inertia: false },
      ...to
    }) => {
      const { inertia, bounds, velocity, ...rest } = config;
      if (inertia) {
        console.log('bounds: ', bounds, inertia);
        set({
          ...to,
          // onChange: { x: () => console.log('hello') },
          // onChange: values => onChange(false, values),
          // onChange: values => console.log('values: ', values),
          onChange: values => {
            console.log('values: ', values);
            Object.entries(values).forEach(([key, v]) => {
              const vel = props[key].velocity;
              const bound =
                v >= bounds[key][1]
                  ? bounds[key][1]
                  : v <= bounds[key][0]
                    ? bounds[key][0]
                    : undefined;
              if (bound !== undefined) {
                props[key].stop();
                set({ [key]: bound, config: { decay: false, velocity: vel } });
              }
            });
          },
          config: k => ({ decay: true, velocity: velocity[k] }),
        });
      }
      else {
        set({ ...to, config: rest });
      }
    },
    [props, set],
  );

  return [props, setInertia];
};
