//import 'babel-polyfill';
import { Action, Reducer } from 'redux';
import { IApplicationState } from './index';
import * as fromPageScroll from './PageScroll';
import mapObject from 'utils/mapObject';

import { createSelector } from 'reselect';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface INavItem {
  title: string;
  url: string;
}

export interface IAreaById extends INavItem {
  exact: boolean;
}

export interface IAreasById {
  [key: number]: IAreaById;
}

export interface INavMenuProps {
  open: boolean;
  peek: boolean;
}

export interface INavMenu extends INavMenuProps {
  //light: boolean;
  load: boolean;
  loaded: boolean;
  triggerHovering: boolean;
}

export interface INavLogo {
  markVisible: boolean;
  hovering: boolean;
}

export interface IMainNavigationState {
  areasById: IAreasById;
  selectedArea: number;
  navMenu: INavMenu;
  navLogo: INavLogo;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export enum keys {
  SET_MAIN_NAVIGATION_CURRENT_AREA = 'SET_MAIN_NAVIGATION_CURRENT_AREA',
  LOAD_NAV_MENU = 'LOAD_NAV_MENU',
  NAV_MENU_LOADED = 'NAV_MENU_LOADED',
  PEEK_AT_NAV_MENU = 'PEEK_AT_NAV_MENU',
  UNPEEK_AT_NAV_MENU = 'UNPEEK_AT_NAV_MENU',
  SHOW_NAV_MENU = 'SHOW_NAV_MENU',
  HIDE_NAV_MENU = 'HIDE_NAV_MENU',
  ENTER_NAV_MENU_TRIGGER = 'ENTER_NAV_MENU_TRIGGER',
  LEAVE_NAV_MENU_TRIGGER = 'LEAVE_NAV_MENU_TRIGGER',
  SHOW_NAV_LOGOMARK = 'SHOW_NAV_LOGOMARK',
  HIDE_NAV_LOGOMARK = 'HIDE_NAV_LOGOMARK',
  ENTER_NAV_LOGO = 'ENTER_NAV_LOGO',
  LEAVE_NAV_LOGO = 'LEAVE_NAV_LOGO',
}

interface ISetMainNavigationCurrentAreaAction {
  type: keys.SET_MAIN_NAVIGATION_CURRENT_AREA;
  linkId?: number;
  url?: string;
}
interface ILoadNavMenuAction {
  type: keys.LOAD_NAV_MENU;
}
interface INavMenuLoadedAction {
  type: keys.NAV_MENU_LOADED;
}
interface IPeekAtNavMenuAction {
  type: keys.PEEK_AT_NAV_MENU;
}
interface IUnpeekAtNavMenuAction {
  type: keys.UNPEEK_AT_NAV_MENU;
}
interface IShowNavMenuAction {
  type: keys.SHOW_NAV_MENU;
}
interface IHideNavMenuAction {
  type: keys.HIDE_NAV_MENU;
}
interface IEnterNavMenuTriggerAction {
  type: keys.ENTER_NAV_MENU_TRIGGER;
}
interface ILeaveNavMenuTriggerAction {
  type: keys.LEAVE_NAV_MENU_TRIGGER;
}
interface IShowNavLogomarkAction {
  type: keys.SHOW_NAV_LOGOMARK;
}
interface IHideNavLogomarkAction {
  type: keys.HIDE_NAV_LOGOMARK;
}
interface IEnterNavLogoAction {
  type: keys.ENTER_NAV_LOGO;
}
interface ILeaveNavLogoAction {
  type: keys.LEAVE_NAV_LOGO;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type IKnownAction =
  ISetMainNavigationCurrentAreaAction
  | ILoadNavMenuAction
  | INavMenuLoadedAction
  | IPeekAtNavMenuAction
  | IUnpeekAtNavMenuAction
  | IShowNavMenuAction
  | IHideNavMenuAction
  | IShowNavLogomarkAction
  | IHideNavLogomarkAction
  | IEnterNavLogoAction
  | ILeaveNavLogoAction
  | IEnterNavMenuTriggerAction
  | ILeaveNavMenuTriggerAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
// We are using them to fire off our events that will be handled by the root sagas and delegated consequently

export const actionCreators = {
  setMainNavigationCurrentAreaAction: (linkId: number) => dispatch => {
    dispatch({
      type: keys.SET_MAIN_NAVIGATION_CURRENT_AREA,
      linkId,
    });
  },
  handleMainNavClickAction: (linkId: number) => dispatch => {
    dispatch({
      type: keys.SET_MAIN_NAVIGATION_CURRENT_AREA,
      linkId,
    });
    setTimeout(() => {
      dispatch(fromPageScroll.actionCreators.restorePageScroll());
      setTimeout(() => {
        dispatch({ type: keys.HIDE_NAV_MENU });
        dispatch({ type: keys.UNPEEK_AT_NAV_MENU });
      }, 500);
    }, 85);
  },
  preloadNavMenuAction: () => dispatch => {
    dispatch({ type: keys.LOAD_NAV_MENU });
  },
  markNavMenuLoadedAction: () => dispatch => {
    dispatch({ type: keys.NAV_MENU_LOADED });
  },
  peekAtNavMenuAction: () => dispatch => {
    dispatch({ type: keys.PEEK_AT_NAV_MENU });
  },
  unpeekAtNavMenuAction: () => dispatch => {
    dispatch({ type: keys.UNPEEK_AT_NAV_MENU });
  },
  showNavMenuAction: () => dispatch => {
    dispatch({ type: keys.SHOW_NAV_MENU });
    dispatch({ type: keys.UNPEEK_AT_NAV_MENU });
    dispatch(fromPageScroll.actionCreators.disablePageScroll());
  },
  hideNavMenuAction: () => dispatch => {
    dispatch(fromPageScroll.actionCreators.restorePageScroll());
    dispatch({ type: keys.HIDE_NAV_MENU });
    dispatch({ type: keys.UNPEEK_AT_NAV_MENU });
  },
  enterNavMenuTriggerAction: () => dispatch => {
    dispatch({ type: keys.ENTER_NAV_MENU_TRIGGER });
  },
  leaveNavMenuTriggerAction: () => dispatch => {
    dispatch({ type: keys.LEAVE_NAV_MENU_TRIGGER });
  },
  showNavLogomarkAction: () => dispatch => {
    dispatch({ type: keys.SHOW_NAV_LOGOMARK });
  },
  hideNavLogomarkAction: () => dispatch => {
    dispatch({ type: keys.HIDE_NAV_LOGOMARK });
  },
  enterNavLogoAction: () => dispatch => {
    dispatch({ type: keys.ENTER_NAV_LOGO });
  },
  leaveNavLogoAction: () => dispatch => {
    dispatch({ type: keys.LEAVE_NAV_LOGO });
  },
};

// ----------------
// SAGA WATCHERS - Register all saga watchers here that will intercept all dispatched calls and delegate appropriately

// ----------------
// SAGA WORKERS - These are saga worker functions that are called when receiving saga dispatches. The saga watchers
// intercept dispatched calls and call the relevant saga functions when appropriate

// ----------------
// ROOT SAGA - Register all saga watchers into one root saga to be initialised in configureStore

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<IMainNavigationState> = (state: IMainNavigationState, action: IKnownAction) => {
  switch (action.type) {
    case keys.SET_MAIN_NAVIGATION_CURRENT_AREA:
      if (action.linkId) {
        return {
          ...state,
          selectedArea: action.linkId,
        };
      }
      else if (action.url) {
        let selectedAreaId = 0;

        mapObject(state.areasById, (navigationItemId, navigationItem) => {
          if (navigationItem.url === action.url) {
            selectedAreaId = navigationItemId;
          }
        });

        return {
          ...state,
          selectedArea: selectedAreaId,
        };
      }
    case keys.LOAD_NAV_MENU:
      return {
        ...state,
        navMenu: {
          ...state.navMenu,
          load: true,
        },
      };
    case keys.NAV_MENU_LOADED:
      return {
        ...state,
        navMenu: {
          ...state.navMenu,
          loaded: true,
        },
      };
    case keys.PEEK_AT_NAV_MENU:
      return {
        ...state,
        navMenu: {
          ...state.navMenu,
          peek: true,
        },
      };
    case keys.UNPEEK_AT_NAV_MENU:
      return {
        ...state,
        navMenu: {
          ...state.navMenu,
          peek: false,
        },
      };
    case keys.SHOW_NAV_MENU:
      return {
        ...state,
        navMenu: {
          ...state.navMenu,
          open: true,
        },
      };
    case keys.HIDE_NAV_MENU:
      return {
        ...state,
        navMenu: {
          ...state.navMenu,
          open: false,
        },
      };
    case keys.ENTER_NAV_MENU_TRIGGER:
      return {
        ...state,
        navMenu: {
          ...state.navMenu,
          triggerHovering: true,
        },
      };
    case keys.LEAVE_NAV_MENU_TRIGGER:
      return {
        ...state,
        navMenu: {
          ...state.navMenu,
          triggerHovering: false,
        },
      };
    case keys.SHOW_NAV_LOGOMARK:
      return {
        ...state,
        navLogo: {
          ...state.navLogo,
          markVisible: true,
        },
      };
    case keys.HIDE_NAV_LOGOMARK:
      return {
        ...state,
        navLogo: {
          ...state.navLogo,
          markVisible: false,
        },
      };
    case keys.ENTER_NAV_LOGO:
      return {
        ...state,
        navLogo: {
          ...state.navLogo,
          hovering: true,
        },
      };
    case keys.LEAVE_NAV_LOGO:
      return {
        ...state,
        navLogo: {
          ...state.navLogo,
          hovering: false,
        },
      };
    default:
      // The following line guarantees that every action in the KnownAction union has been covered by a case above
      const exhaustiveCheck: never = action;
  }

  // For unrecognized actions (or in cases where actions have no effect), must return the existing state
  //  (or default initial state if none was supplied)
  return state || {
    areasById: {
      1: {
        title: 'Home',
        exact: true,
        url: '/',
      },
      2: {
        title: 'Work',
        exact: true,
        url: '/work',
      },
      3: {
        title: 'Services',
        exact: true,
        url: '/services',
      },
      4: {
        title: 'Our Story',
        exact: true,
        url: '/our-story',
      },
      5: {
        title: 'Contact Us',
        exact: true,
        url: '/contact',
      },
    },
    selectedArea: 0,
    //navMenuLight: true,
    navMenu: {
      peek: false,
      open: false,
      load: false,
      loaded: false,
      triggerHovering: false,
    },
    navLogo: {
      markVisible: true,
      hovering: false,
    },
  };
};

// ----------------
// SELECTORS - These are functions exposed to UI components that will give them access to the associated store components.
// They only return the reference to the required state in the store, they don't change it.

const mainNavigationSelector = (state: IApplicationState) => state.mainNavigation;

const areasByIdSelector = (state: IApplicationState) => mainNavigationSelector(state).areasById;
export const getAreasById = createSelector(
  [areasByIdSelector],
  areasById => {
    return areasById;
  },
);
export const getAreasAmount = createSelector(
  [areasByIdSelector],
  areasById => {
    return Object.keys(areasById).length;
  },
);

// A good guide for memoization using reselect  https://blog.isquaredsoftware.com/2017/12/idiomatic-redux-using-reselect-selectors/

export const getMainNavItems = createSelector(
  [areasByIdSelector],
  areasById => {
    const navItems: INavItem[] = [];
    mapObject(areasById, (navigationItemId, navigationItem: IAreaById) => {
      const navItem: INavItem = {
        title: navigationItem.title,
        url: navigationItem.url,
      };
      navItems.push(navItem);
    });

    return navItems;
  },
);

const navMenuSelector = (state: IApplicationState) => mainNavigationSelector(state).navMenu;
export const getNavMenu = createSelector(
  [navMenuSelector],
  navMenu => {
    return navMenu;
  },
);

export const getNavMenuProps = createSelector(
  [navMenuSelector],
  navMenu => {
    return {
      open: navMenu.open,
      peek: navMenu.peek,
    } as INavMenuProps;
  },
);

export const getNavMenuOpen = createSelector(
  [navMenuSelector],
  navMenu => {
    return navMenu.open;
  },
);

export const getHomeUrl = createSelector(
  [areasByIdSelector],
  areasById => {
    return areasById[1].url;
  },
);

const navLogoSelector = (state: IApplicationState) => mainNavigationSelector(state).navLogo;
export const getNavLogo = createSelector(
  [navLogoSelector],
  navLogo => {
    return navLogo;
  },
);
