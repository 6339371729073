import React, { useEffect, useState } from 'react';
import { useTrail, SpringConfig } from 'react-spring';
import { animateInSpringConfig } from 'components/Animations/SpringProperties/SpringProperties';

export interface IAnimateInProps {
  animationReady: boolean;
}

export const useAnimateInTrail = (animateInStatus: boolean, amount: number, customSpringConfig: SpringConfig = {}) => {

  const springConfig: SpringConfig = {
    ...(customSpringConfig !== {} ? customSpringConfig : animateInSpringConfig),
    mass: 1,
    clamp: true,
  };

  // const [refresh, setRefresh] = useState(0);

  const [animateInSpring, setAnimateInSpring] = useTrail(amount, () => ({
    active: 0,
    // from: { active: 0 },
    config: springConfig,
    // onStart: () => {setRefresh(i => i++); console.log('starting animation'); },
  }));

  useEffect(() => {
    setAnimateInSpring({
      active: animateInStatus ? 1 : 0,
    });
  }, [animateInStatus]);

  return animateInSpring;
};
