import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import { useRouteMatch } from 'react-router-dom';

import path from 'path';
import Loadable from 'react-loadable';

import { a } from 'react-spring';

import classNames from 'classnames';

import { HeaderMenu } from './HeaderMenu/HeaderMenu';
import { BackToWorkTrigger, IBackgroundShade } from 'components/InterfaceOverlay/InterfaceTrigger/Index';
//import ChatModal from 'components/InterfaceOverlay/ContactChatModal/ChatModal/ChatModal';
import InPageNavigation from 'components/InterfaceOverlay/InPageNavigation/InPageNavigation';
import InPageNavigationProps from 'components/InterfaceOverlay/InPageNavigation/InPageNavigationProps';

const ChatModal = Loadable({
  loader: () => import(/* webpackChunkName: "ChatModal" */ 'components/InterfaceOverlay/ContactChatModal/ChatModal'),
  loading: () => <div className='loadingNewComponent' />,
  delay: 200,
  //serverSideRequirePath: path.join(__dirname, 'components/InterfaceOverlay/ContactChatModal/ChatModal'),
  modules: ['components/InterfaceOverlay/ContactChatModal/ChatModal'],
  webpack: () => [require.resolveWeak('components/InterfaceOverlay/ContactChatModal/ChatModal')],
});

interface IOwnProps {
  backgroundShade: IBackgroundShade;
  mainSiteOverlay: boolean;
  wrapperStyle?: object;
  onlyChatModal?: boolean;
}

type IProps = IOwnProps;

const InterfaceOverlay: React.FC<IProps> = forwardRef((
  {
    backgroundShade = 'dark' as 'dark',
    mainSiteOverlay = false,
    wrapperStyle = {},
    onlyChatModal = false,
  },
  ref,
) => {
  const myRef = useRef();
  useImperativeHandle(ref, () => ({}));

  const workDetailsMatch = useRouteMatch({
    path: '/work/:projectName',
    strict: true,
    sensitive: true,
  });

  return (
    <a.div
      ref={myRef}
      className={classNames(
        'interfaceOverlay',
        { clipToSection: !mainSiteOverlay },
      )}
      style={wrapperStyle}
    >
      {!onlyChatModal ? <HeaderMenu backgroundShade={backgroundShade} mainSiteOverlay={mainSiteOverlay} /> : null}
     {workDetailsMatch ?
        mainSiteOverlay ?
          <InPageNavigationProps key='inPageNav' />
          :
          <InPageNavigation backgroundShade={backgroundShade} key='inPageNav' />
        :
        null
      }
      {workDetailsMatch ? <BackToWorkTrigger backgroundShade={backgroundShade} mainSiteOverlay={mainSiteOverlay} key='backToWorkTrigger' /> : null}
      <ChatModal
        backgroundShade={backgroundShade}
        mainSiteOverlay={mainSiteOverlay}
      />
    </a.div>
  );
});

export default a(InterfaceOverlay);
