import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as fromInPageNavigation from 'store/InPageNavigation';

import { useScrollTarget } from 'hooks/useScrollTarget';
import { usePrevious } from 'utils/Index';

const InPageNavigationProps: React.FC<{}> = () => {

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Redux functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const dispatch = useDispatch();

  const scrollToSectionNumberAction = useCallback(
    (section: number) => dispatch(fromInPageNavigation.actionCreators.scrollToSectionNumberAction(section))
    , [dispatch]);

  const setCurrentSectionNumberAction = useCallback(
    (section: number) => dispatch(fromInPageNavigation.actionCreators.setCurrentSectionNumberAction(section))
    , [dispatch]);

  const showNav = useCallback(
    () => dispatch(fromInPageNavigation.actionCreators.showNavAction())
    , [dispatch]);

  const hideNav = useCallback(
    () => dispatch(fromInPageNavigation.actionCreators.hideNavAction())
    , [dispatch]);

  const animateScrollTo = useScrollTarget(true, null, () => scrollToSectionNumberAction(-1));

  const visible = useSelector(fromInPageNavigation.getVisibility);
  const currentSectionNumber = useSelector(fromInPageNavigation.getCurrentSectionNumber);
  const numberOfSections = useSelector(fromInPageNavigation.getTotalSectionsNumber);
  const navigationArray = useSelector(fromInPageNavigation.getNavigationArray);
  const scrollToSectionNumber = useSelector(fromInPageNavigation.getScrollToSectionNumber);
  const previousScrollToSectionNumber = usePrevious(scrollToSectionNumber);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Track window scroll position functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    const handleScroll = () => {
      const y = window.scrollY;

      if (numberOfSections > 0) {
        if (navigationArray.length > 0) {
          let newCurrentSectionNumber: number = 1;

          let show = false;
          if (navigationArray.length > 0) {
            if (((200 + y) > navigationArray[0].scrollTop) && ((200 + y) < (navigationArray[navigationArray.length - 1].scrollTop + navigationArray[navigationArray.length - 1].height)))
            show = true;
          }

          if (show && !visible)
            showNav();
          else if (!show && visible)
            hideNav();

          navigationArray.map((navSection, i) => {
            const currentNavProps = navSection;
            const scrollProgress = (y - currentNavProps.scrollTop) / currentNavProps.height;

            const currentSection = (scrollProgress >= 0 && scrollProgress < 1);

            if (currentSection || (i + 1 === numberOfSections && scrollProgress > 0))
              newCurrentSectionNumber = i + 1;
          });
          if (newCurrentSectionNumber !== currentSectionNumber)
            setCurrentSectionNumberAction(newCurrentSectionNumber);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [numberOfSections, navigationArray, currentSectionNumber,  visible]);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Animate to section functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (scrollToSectionNumber !== previousScrollToSectionNumber && scrollToSectionNumber !== -1) {
      const finalScrollTop = navigationArray[scrollToSectionNumber - 1].scrollTop + 5;
      animateScrollTo(finalScrollTop);
    }
  }, [scrollToSectionNumber, navigationArray]);

  return null;
};

export default InPageNavigationProps;
