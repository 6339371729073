import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useTimeout } from 'utils/Index';

import classNames from 'classnames';

import { useSpring, a, SpringConfig } from 'react-spring';
import { defaultSpringConfig } from 'components/Animations/SpringProperties/SpringProperties';

import { preloadRouteComponent } from 'app/routes';

interface IProps {
  to?: string;
  size?: string;
  type?: string;
  scheme?: string;
  id?: string;
  isActive?: boolean;
  onClick?: () => void;
  newWindow?: boolean;
  title: string;
}

const SimpleLink: React.FC<IProps> = ({
  to = null,
  type = '',
  size = 'normal',
  scheme,
  id = '',
  isActive = false,
  newWindow = false,
  title = '',
  ...props
}) => {
  const [clicked, setClicked] = useState(false);
  const [hovering, setHovering] = useState(false);

  const [delay, setDelay] = useState(500);
  const [triggerTimeout, setTriggerTimeout] = useState(false);

  useTimeout(() => {
    setClicked(false);
  }, triggerTimeout ? delay : null);

  const handleClick = () => {
    if (props.onClick)
      props.onClick();
    setTriggerTimeout(false);
    setClicked(true);
    setTriggerTimeout(true);
  };

  const handleMouseEnter = () => {
    if (!hovering)
      setHovering(true);
    if (to != null)
      preloadRouteComponent(to);
  };

  const handleMouseLeave = () => {
    if (hovering || clicked) {
      setHovering(false);
      setClicked(false);
    }
  };

  const inputProps: { [k: string]: any } = {
    className: classNames(
      'simple-link',
      size,
      { scheme: scheme != null },
      { active: isActive },
    ),
    id,
    onClick: handleClick,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    target: (newWindow ? '_blank' : null),
    rel: (newWindow ? 'noopener' : null),
    title,
  };

  const springConfig: SpringConfig = {
    ...defaultSpringConfig,
    tension: 340,
    friction: 36,
  };

  const [hoveringSpring, setHoveringSpring] = useSpring(() => ({
    x: 0,
    config: springConfig,
  }));

  useEffect(() => {
    setHoveringSpring({
      x: hovering ? 100 : 0,
    });
  }, [hovering]);

  const underlineStyles = {
    width: hoveringSpring.x.to(x => `calc(${x} * 1%)`),
  };

  const LinkUnderline = <a.div className='underline' style={underlineStyles} />;

  const LinkText = <span>{props.children}</span>;

  if (to == null)
    return (
      <div {...inputProps} >
        {LinkUnderline}
        {LinkText}
      </div>
    );
  else if (type === 'classic')
    return (
      <a href={to} {...inputProps}>
        {LinkUnderline}
        {LinkText}
      </a>
    );
  else
    return (
      <Link to={to} {...inputProps} >
        {LinkUnderline}
        {LinkText}
      </Link>
    );

};

export default SimpleLink;
