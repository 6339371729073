import React, { useState, useEffect, useCallback, useLayoutEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, matchPath } from 'react-router-dom';

import { useSpring, a, SpringConfig, to, useTrail, useSprings } from 'react-spring';
import { useInertialSpring } from 'hooks/useInertialSpring';
import { useGesture } from 'react-use-gesture';
import { DragConfig, FullGestureState } from 'react-use-gesture/dist/types';
import { IDraggableBounds } from 'hooks/useDraggable';
import { useDisabledScrollWidth } from 'hooks/useDisabledScrollWidth';

import classNames from 'classnames';

import * as fromMainNavigation from 'store/MainNavigation';

import SimpleLink from 'components/Links/SimpleLink/SimpleLink';

import { useAnimateInTrail } from 'hooks/useAnimateInTrail';
import { initialOffset, defaultSpringConfig } from 'components/Animations/SpringProperties/SpringProperties';
import { mapObject, getPixelRatio, useLatest, usePrevious } from 'utils/Index';

export interface INavAnimationOpacity {
  opacity: number;
  variance: number;
  topBoundaryDistance: number;
  bottomBoundaryDistance: number;
  animationFrameSpeed: number;
  animationStartFrame: number;
  targetOpacity: number;
  totalOpacity: number;
}

export interface INavAnimationItem {
  type: string;
  x: number;
  y: number;
  changeX: number;
  changeY: number;
  angle: number;
  section: INavAnimationOpacity[];
  totalOpacity: number;
}

const NavMenu: React.FC<{}> = props => {
  const location = useLocation();

  const [animateIn, setAnimateIn] = useState(false);

  const navMenu = useSelector(fromMainNavigation.getNavMenuProps);
  const latestNavMenuOpen = useLatest(navMenu.open);
  const areasById = useSelector(fromMainNavigation.getAreasById);
  const areasAmount = useSelector(fromMainNavigation.getAreasAmount);

  const dispatch = useDispatch();

  const markNavMenuLoadedAction = useCallback(() => dispatch(fromMainNavigation.actionCreators.markNavMenuLoadedAction()), [dispatch]);

  const hideNavMenuAction = useCallback(
    () => dispatch(fromMainNavigation.actionCreators.hideNavMenuAction())
    , [dispatch]);

  const showNavMenuAction = useCallback(
    () => dispatch(fromMainNavigation.actionCreators.showNavMenuAction())
    , [dispatch]);

  const handleMainNavClickAction = useCallback(
    (linkId: number) => dispatch(fromMainNavigation.actionCreators.handleMainNavClickAction(linkId))
    , [dispatch]);

  const navMenuRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [navMenuWidth, setNavMenuWidth] = useState(400);
  const [navMenuHeight, setNavMenuHeight] = useState(0);
  const latestNavMenuHeight = useLatest(navMenuHeight);
  const previousNavMenuHeight = usePrevious(navMenuHeight);

  const updateMenuDimensions = () => {
    const navMenuProps = navMenuRef.current.getBoundingClientRect();
    setNavMenuHeight(navMenuProps.height);
    setNavMenuWidth(navMenuProps.width);
  };

  useLayoutEffect(() => {
    markNavMenuLoadedAction();
    updateMenuDimensions();

    // Setup event listeners on initial mount to keep the  dimensions of the menu updated
    window.addEventListener("resize", updateMenuDimensions);

    return () => {
      window.removeEventListener("resize", updateMenuDimensions);
    };
  }, []);

  useEffect(() => {
    if (previousNavMenuHeight !== undefined) {
      const oldRange = previousNavMenuHeight !== undefined ? previousNavMenuHeight : 100;
      if (navMenuHeight !== previousNavMenuHeight && navMenuHeight !== 0 && previousNavMenuHeight !== 0) {
        const currentXPosition = openMenuYPosition.get();
        const percentageXPosition = (currentXPosition / oldRange);
        setOpenMenuYPosition({
          y: (percentageXPosition * navMenuHeight),
          from: { x: (percentageXPosition * oldRange) * (oldRange / navMenuHeight) },
          immediate: false,
          config: { ...defaultSpringConfig },
        });
      }
    }
  }, [previousNavMenuHeight, navMenuHeight]);

  const animateCursorSpringConfig: SpringConfig = {
    ...defaultSpringConfig,
    tension: 170,
  };
  const [cursorPosition, setCursorPosition] = useSpring(() => ({ x: 0, y: 0, springConfig: animateCursorSpringConfig }));
  const [{ y: openMenuYPosition }, setOpenMenuYPosition] = useInertialSpring({ x: 0, y: 0 });
  const [isBeingDragged, setIsBeingDragged] = useState(false);
  // const [menuIsOpen, setMenuIsOpen] = useState(false);

  const bounds: IDraggableBounds = {
    y: [0, latestNavMenuHeight.current],
    x: [0, 0],
  };

  const gestureOptions: DragConfig = {
    initial: () => [0, openMenuYPosition.get()],
    rubberband: true,
    lockDirection: true,
    axis: 'y',
    bounds: {
      top: bounds.y[0],
      bottom: bounds.y[1],
      left: bounds.x[0],
      right: bounds.x[1],
    },
  };
  const bind = useGesture(
    {
      onDrag: state => handleDrag(state),
      onMove: state => handleMove(state),
    },
    { drag: { ...gestureOptions, filterTaps: true } },
  );

  const handleDrag = (state: FullGestureState<'drag'>) => {
    const { movement: [mx, my], vxvy: [vx, vy], first, last, cancel, canceled } = state;

    if (!latestNavMenuOpen.current) {
      showNavMenuAction();
    }

    if (first)
      setIsBeingDragged(true);
    else if (last)
      setIsBeingDragged(false);

    // if the user drags the menu down past a threshold, then we cancel
    // the drag so that the menu resets to its fully open position
    if (my > latestNavMenuHeight.current + 70) cancel();

    const commonConfig = { bounds, velocity: { x: vx, y: vy } };

    // when the user releases the sheet, we check whether it passed
    // the threshold for it to close, or if we reset it to its open position
    if (last) {
      if (my < latestNavMenuHeight.current * 0.75 || vy < -0.5) {
        // the user has either released the menu with less than 75% of it showing or they have
        // released it with a vertical velocity of -0.5 so we assume they want to close the menu
        setOpenMenuYPosition({
          x: mx,
          y: 0,
          // onRest: () => console.log('draggable rest'),
          config: { ...defaultSpringConfig, ...commonConfig },
        });
        hideNavMenuAction();
      } else {
        // the user still has the menu more than 75% open and there is not a negative velocity of
        // greater than the threshold of 0.5
        if (vy > 0.5) {
          // if out vertical velocity is above 0.5 then we have flicked it down, so lets use the
          // natural velocity with a little rebound
          setOpenMenuYPosition({
            x: mx,
            y: my,
            config: { inertia: true, ...commonConfig },
          });
        } else {
          // otherwise we just animate to the fully open menu state
          setOpenMenuYPosition({
            x: mx,
            y: latestNavMenuHeight.current,
            config: { ...openMenuSpringConfig, ...commonConfig },
          });
        }
      }
    } else {
      // when the user keeps dragging, we just move the sheet according to
      // the cursor position
      setOpenMenuYPosition({
        x: mx,
        y: my,
        immediate: true,
        config: { decay: false },
      });
    }
  };

  const handleMove = (state: FullGestureState<'move'>) => {
    const { xy: [x, y] } = state;
    setCursorPosition({ x, y, springConfig: animateCursorSpringConfig });
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const ratio = getPixelRatio(ctx);

    canvas.width = navMenuWidth * ratio;
    canvas.height = navMenuHeight * ratio;
    canvas.style.width = `${navMenuWidth}px`;
    canvas.style.height = `${navMenuHeight}px`;

    function drawLine(x, y, width, height, degrees, opacity) {

      // first save the untranslated/unrotated context
      ctx.save();

      ctx.beginPath();
      // move the rotation point to the center of the rect
      ctx.translate(x, y);
      //ctx.translate(x + width / 2, y + height / 2);
      // rotate the rect
      const finalAngle = degrees + 90;
      ctx.rotate(finalAngle * Math.PI / 180);

      // draw the rect on the transformed context
      // Note: after transforming [0,0] is visually [x,y]
      //       so the rect needs to be offset accordingly when drawn
      ctx.rect(-width / 2, -height / 2, width, height);

      ctx.fillStyle = `rgba(225,225,225,${opacity})`;
      ctx.fill();

      // restore the context to its untranslated/unrotated state
      ctx.restore();

    }

    const convertRadToDeg = rad => {
      return rad * 180 / Math.PI;
    };

    let requestId;
    const render = () => {
      const margin = 10;

      const cursorX = cursorPosition.x.get() * ratio;
      const cursorY = cursorPosition.y.get() * ratio;

      const lineGap = 80;
      const lineLength = 30;
      const lineWidth = 2;

      const boardWidth = canvas.width - (margin * 2);
      const boardHeight = canvas.height - (margin * 2);
      const boardMaxDistance = Math.sqrt(Math.pow(Math.abs(boardWidth), 2) + Math.pow(Math.abs(boardHeight), 2));

      const numberOfColumns = Math.floor(boardWidth / lineGap);
      const numberOfRows = Math.floor(boardHeight / lineGap);

      const cursorRange = boardWidth;

      const gridWidth = (numberOfColumns - 1) * lineGap;
      const gridHeight = (numberOfRows - 1) * lineGap;

      const firstColumnX = margin + ((boardWidth - gridWidth) / 2);
      const firstRowY = margin + ((boardHeight - gridHeight) / 2);

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      for (let r = 0; r < numberOfRows; r++) {
        for (let c = 0; c < numberOfColumns; c++) {
          const lineX = firstColumnX + (c * lineGap);
          const lineY = firstRowY + (r * lineGap);

          const cursorXDistance = cursorX - lineX;
          const cursorYDistance = cursorY - lineY;
          const cursorLineDistance = Math.sqrt(Math.pow(Math.abs(cursorXDistance), 2) + Math.pow(Math.abs(cursorYDistance), 2));

          const lineAngle = convertRadToDeg(Math.atan2(cursorYDistance, cursorXDistance)) + (Math.random() * cursorLineDistance / boardMaxDistance * 30);

          const scaledOpacity = (cursorLineDistance < cursorRange ? cursorLineDistance / cursorRange : 1);
          const lineOpacity = (0.25) * scaledOpacity;

          drawLine(lineX, lineY, lineLength, lineWidth, lineAngle, lineOpacity);
        }
      }


      requestId = requestAnimationFrame(render);
    };

    render();

    return () => {
      cancelAnimationFrame(requestId);
    };

  });

  useEffect(() => {
    if (navMenu.open && !animateIn) {
      updateMenuDimensions();
      setAnimateIn(true);
    }
    else if (!navMenu.open && animateIn) {
      setAnimateIn(false);
    }
  }, [navMenu.open, animateIn]);

  const openMenuSpringConfig: SpringConfig = {
    // mass: 1,
    tension: 250,
    friction: 36,
    clamp: true,
    // precision: 0.01,
    decay: false,
    velocity: 0,
    //duration: undefined,
    // easing: t => t,
  };

  useEffect(() => {
    if (navMenu.open && !isBeingDragged) {
      // setMenuIsOpen(true);
      setOpenMenuYPosition({ y: navMenuHeight, immediate: false, config: openMenuSpringConfig });
    } else if (openMenuYPosition.goal !== 0) {
      setOpenMenuYPosition({ y: 0, immediate: false, config: openMenuSpringConfig });
    }
  }, [navMenu.open, navMenuHeight]);

  const peekMenuSpringConfig: SpringConfig = {
    ...openMenuSpringConfig,
    tension: 500,
  };
  const [peekMenuSpring, setPeekMenuSpring] = useSpring(() => ({ x: 0, config: peekMenuSpringConfig }));

  useEffect(() => {
    setPeekMenuSpring({ x: navMenu.peek ? 1 : 0 });
  }, [navMenu.peek]);

  const contentOverlaySpringConfig: SpringConfig = peekMenuSpringConfig;

  const [contentOverlaySpring, setContentOverlaySpring] = useSpring(() => ({ x: 0, config: contentOverlaySpringConfig }));

  useEffect(() => {
    setContentOverlaySpring({ x: navMenu.peek ? 1 : 0 });
  }, [navMenu.peek]);

  const sbw = useDisabledScrollWidth();

  const navMenuPeekStyles = {
    transform: peekMenuSpring.x.to(x => `translateY(calc((-100%) + (${x} * 100%)))`),
    // right: sbw,
    right: sbw.width.to(x => x),
  };

  const globalNavMenuStyles = {
    opacity: 1,
    transform: openMenuYPosition.to(y => `translateY(calc(-100% + ${y}px))`),
    // right: sbw,
    right: sbw.width.to(x => x),
  };

  const globalNavOverlayStyles = {
    opacity: to(
      [contentOverlaySpring.x, openMenuYPosition],
      (o: number, y: number) => ((0.7 * y / navMenuHeight) + (0.55 * o)),
    ),
    display: to(
      [contentOverlaySpring.x, openMenuYPosition],
      (o: number, y: number) => (o === 0 && y === 0 ? 'none' : 'block'),
    ),
    cursor: openMenuYPosition.to(y => y === 0 ? 'default' : 'pointer'),
  };

  const animateInTrail = useAnimateInTrail(animateIn, areasAmount + 6); // This should really only have like 8 but it doesnt work with that for some reason

  const navLinkStyles = (i: number) => ({
    opacity: animateInTrail[i].active,
    transform: animateInTrail[i].active.to(x => `translateY(calc(${initialOffset(x, 20)}))`),
  });

  const [currentPath, setCurrentPath] = useState(null);

  useEffect(() => {
    mapObject(areasById, (navigationItemId, navigationItem) => {
      const match = matchPath(location.pathname, {
        path: navigationItem.url,
        exact: false,
        strict: false,
      });
      if (match != null)
        setCurrentPath(navigationItemId);
    });
  }, [location, areasById]);

  return (
    <nav className='navMenuWrapper'>
      <a.div className='navMenuPeekWrapper' style={navMenuPeekStyles} />
      <a.div
        {...bind()}
        ref={navMenuRef}
        className={classNames(
          'global-nav-menu',
          { navMenuOpen: navMenu.open },
          { isBeingDragged },
        )}
        style={globalNavMenuStyles}
      >
        <div className="gapCover" />
        <canvas ref={canvasRef} />
        <ul className='global-nav-list'>
          {mapObject(areasById, (navigationItemId, navigationItem) => {
            return (
              <a.li
                key={navigationItemId}
                style={navLinkStyles(navigationItemId)}
              >
                <SimpleLink
                  to={navigationItem.url}
                  id={navigationItemId}
                  onClick={() => handleMainNavClickAction(navigationItemId)}
                  size='large'
                  title={navigationItem.title}
                  isActive={currentPath === navigationItemId}
                >
                  {navigationItem.title}
                </SimpleLink>
              </a.li>
            );
          })}
        </ul>
        <div className='details-bar'>
          <a.div
            className='details-wrapper emailAddress'
            style={navLinkStyles(areasAmount + 2)}
          >
            <SimpleLink to='mailto:hey@qorestudio.com' type='classic' size='medium' title='hey@qorestudio.com'>
              hey@qorestudio.com
            </SimpleLink>
          </a.div>
          <a.div
            className='details-wrapper phoneNumber'
            style={navLinkStyles(areasAmount + 1)}
          >
            <SimpleLink to='tel:07704124123' type='classic' size='medium' title='0770 412 4123'>
              0770 412 4123
            </SimpleLink>
          </a.div>
          <a.div
            className='details-wrapper socialIcons'
            style={navLinkStyles(areasAmount + 2)}
          >
            <div>Colchester, UK</div>
            {/*<SimpleLink to='https://www.facebook.com' type='classic' newWindow size='medium' title='Facebook'>
                        Facebook
                      </SimpleLink>*/}
          </a.div>
        </div>
      </a.div>
      <a.div
        className={classNames(
          'global-nav-overlay',
          { navMenuOpen: openMenuYPosition.to(y => y !== 0) },
        )}
        onClick={hideNavMenuAction}
        style={globalNavOverlayStyles}
      />
    </nav>
  );
};

export default NavMenu;
