import { Reducer } from 'redux';
import { IApplicationState } from './index';

import { createSelector } from 'reselect';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IPageScrollState {
  top: number;
  enabled: boolean;
  wasEnabledBefore: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export enum keys {
  UPDATE_PAGE_SCROLL_TOP = 'UPDATE_PAGE_SCROLL_TOP',
  RECORD_PAGE_SCROLL_TOP_AND_DISABLE = 'RECORD_PAGE_SCROLL_TOP_AND_DISABLE',
  ENABLE_PAGE_SCROLL = 'ENABLE_PAGE_SCROLL',
  RESTORE_PAGE_SCROLL = 'RESTORE_PAGE_SCROLL',
  DISABLE_PAGE_SCROLL = 'DISABLE_PAGE_SCROLL',
  DISABLE_RECORD_PAGE_SCROLL = 'DISABLE_RECORD_PAGE_SCROLL',
}

interface IUpdatePageScrollTopAction {
  type: keys.UPDATE_PAGE_SCROLL_TOP;
  scrollTop: number;
}
interface IRecordPageScrollTopAndDisableAction {
  type: keys.RECORD_PAGE_SCROLL_TOP_AND_DISABLE;
  scrollTop: number;
}
interface IEnablePageScrollAction { type: keys.ENABLE_PAGE_SCROLL; }
interface IRestorePageScrollAction { type: keys.RESTORE_PAGE_SCROLL; }
interface IDisablePageScrollAction { type: keys.DISABLE_PAGE_SCROLL; }
interface IDisableRecordPageScrollAction { type: keys.DISABLE_RECORD_PAGE_SCROLL; }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type IKnownAction = IUpdatePageScrollTopAction
  | IRecordPageScrollTopAndDisableAction
  | IEnablePageScrollAction
  | IRestorePageScrollAction
  | IDisableRecordPageScrollAction
  | IDisablePageScrollAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
// We are using them to fire off our events that will be handled by the root sagas and delegated consequently

export const actionCreators = {
  restorePageScroll: () => dispatch => {
    dispatch({ type: keys.RESTORE_PAGE_SCROLL });
  },
  enablePageScroll: () => dispatch => {
    dispatch({ type: keys.ENABLE_PAGE_SCROLL });
  },
  disablePageScroll: (recordPageScroll: boolean = true) => dispatch => {
    if (recordPageScroll) {
      const scrollTop = (typeof window !== 'undefined' ? window.scrollY : 0);
      dispatch({ type: keys.RECORD_PAGE_SCROLL_TOP_AND_DISABLE, scrollTop });
      //dispatch({ type: keys.UPDATE_PAGE_SCROLL_TOP, scrollTop });
      //dispatch({ type: keys.DISABLE_RECORD_PAGE_SCROLL });

    }
    else
      dispatch({ type: keys.DISABLE_PAGE_SCROLL });
  },
};

// ----------------
// SAGA WATCHERS - Register all saga watchers here that will intercept all dispatched calls and delegate appropriately

// ----------------
// SAGA WORKERS - These are saga worker functions that are called when receiving saga dispatches. The saga watchers
// intercept dispatched calls and call the relevant saga functions when appropriate

// ----------------
// ROOT SAGA - Register all saga watchers into one root saga to be initialised in configureStore

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<IPageScrollState> = (state: IPageScrollState, action: IKnownAction) => {
  switch (action.type) {
    case keys.UPDATE_PAGE_SCROLL_TOP:
      return {
        ...state,
        top: action.scrollTop,
      };
    case keys.RECORD_PAGE_SCROLL_TOP_AND_DISABLE:
      return {
        ...state,
        top: action.scrollTop,
        enabled: false,
        wasEnabledBefore: state.enabled,
      };
    case keys.ENABLE_PAGE_SCROLL:
      return {
        ...state,
        enabled: true,
      };
    case keys.RESTORE_PAGE_SCROLL:
      return {
        ...state,
        enabled: state.wasEnabledBefore,
      };
    case keys.DISABLE_RECORD_PAGE_SCROLL:
      return {
        ...state,
        enabled: false,
        wasEnabledBefore: state.enabled,
      };
    case keys.DISABLE_PAGE_SCROLL:
      return {
        ...state,
        enabled: false,
      };
    default:
      // The following line guarantees that every action in the KnownAction union has been covered by a case above
      const exhaustiveCheck: never = action;
  }

  // For unrecognized actions (or in cases where actions have no effect), must return the existing state
  //  (or default initial state if none was supplied)
  return state || {
    top: null,
    enabled: true,
    wasEnabledBefore: true,
  };
};

// ----------------
// SELECTORS - These are functions exposed to UI components that will give them access to the associated store components.
// They only return the reference to the required state in the store, they don't change it.

const pageScrollSelector = (state: IApplicationState) => state.pageScroll;

export const getPageScroll = createSelector(
  [pageScrollSelector],
  pageScroll => {
    //console.log("Output selector running: getPageScroll");
    return pageScroll;
  },
);

const pageScrollTopSelector = (state: IApplicationState) => pageScrollSelector(state).top;
export const getTop = createSelector(
  [pageScrollTopSelector],
  top => {
    //console.log("Output selector running: getTop");
    return top;
  },
);

const pageScrollEnabledSelector = (state: IApplicationState) => pageScrollSelector(state).enabled;
export const getEnabled = createSelector(
  [pageScrollEnabledSelector],
  pageScrollEnabled => {
    //console.log("Output selector running: getEnabled");
    return pageScrollEnabled;
  },
);

const wasEnabledBeforeSelector = (state: IApplicationState) => pageScrollSelector(state).wasEnabledBefore;
export const getWasEnabledBefore = createSelector(
  [wasEnabledBeforeSelector],
  wasEnabledBefore => {
    //console.log("Output selector running: getWasEnabledBefore");
    return wasEnabledBefore;
  },
);

export const getPageOffset = createSelector(
  [getEnabled, getTop],
  (enabled, top) => {
    //console.log("Output selector running: getPageOffset");
    return (!enabled ? top : 0);
  },
);
