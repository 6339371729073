import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import classNames from 'classnames';

import { useSpring, a, SpringConfig } from 'react-spring';
import { defaultSpringConfig } from 'components/Animations/SpringProperties/SpringProperties';

import BlockLink from 'components/Links/BlockLink/BlockLink';

import { IApplicationState } from 'store/index';
import * as fromUserInterface from 'store/UserInterface';
import * as fromMainNavigation from 'store/MainNavigation';

import { useTimeout, useLatest } from 'utils/Index';

export type IBackgroundShade = 'light' | 'dark';
export type IAlign = 'left' | 'right';

export interface ITriggerProps {
  backgroundShade: IBackgroundShade;
  mainSiteOverlay: boolean;
}

interface IOwnSpecificProps {
  active: boolean;
  title: string;
  url: string;
  align: IAlign;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
}

type IOwnProps = ITriggerProps & IOwnSpecificProps;

interface IStateProps {
  animateContentIn: boolean;
  navMenuOpen: boolean;
}

type IDispatchProps = typeof fromUserInterface.actionCreators;

type IProps = IStateProps & IDispatchProps & IOwnProps;

const InterfaceTriggerComponent: React.FC<IProps> = ({
  mainSiteOverlay,
  animateContentIn,
  navMenuOpen,
  active,
  backgroundShade,
  title,
  url,
  align,
  ...props
}) => {
  const [animateIn, setAnimateIn] = useState(false);
  const [hovering, setHovering] = useState(false);

  const [delay, setDelay] = useState(2000);
  const [initialised, setInitialised] = useState(false);
  const [triggerTimeout, setTriggerTimeout] = useState(false);

  const springConfig: SpringConfig = {
    ...defaultSpringConfig,
    tension: 400,
  };
  const [hoveringSpring, setHoveringSpring] = useSpring(() => ({ active: 0, config: springConfig }));

  useEffect(() => {
    setHoveringSpring({ active: active ? 1 : 0 });
  }, [active]);

  const [animateInSpring, setAnimateInSpring] = useSpring(() => ({ active: 0, config: springConfig }));

  useEffect(() => {
    setAnimateInSpring({ active: animateIn ? 1 : 0 });
  }, [animateIn]);

  const latestInitialised = useLatest(initialised);

  useTimeout(() => {
    if (!latestInitialised.current) {
      handleTriggerMouseLeave();
      setInitialised(true);
    }
    setTriggerTimeout(false);
  }, triggerTimeout ? delay : null);

  useEffect(() => {
    if (animateContentIn && !animateIn) {
      setAnimateIn(true);
      if (!initialised && mainSiteOverlay) {
        showTrigger();
        setTriggerTimeout(true);
      }
    }
  }, [animateContentIn, animateIn, initialised, mainSiteOverlay, hovering]);

  useEffect(() => {
    if (active && !initialised && !mainSiteOverlay)
      setInitialised(true);
  }, [active, initialised, mainSiteOverlay]);

  const showTrigger = () => {
    if (props.onMouseEnter)
      props.onMouseEnter();
    if (!hovering)
      setHovering(true);
  };

  const handleTriggerMouseEnter = () => {
    showTrigger();
    if (!initialised) {
      setInitialised(true);
    }
  };

  const handleTriggerMouseLeave = () => {
    if (props.onMouseLeave)
      props.onMouseLeave();
    if (hovering)
      setHovering(false);
  };

  const navSpringConfig: SpringConfig = {
    ...springConfig,
    tension: 170,
    friction: 36,
    clamp: false,
  };
  const [navSpring, setNavSpring] = useSpring(() => ({ x: 0, config: navSpringConfig }));

  useEffect(() => {
    setNavSpring({ x: navMenuOpen ? 1 : 0 });
  }, [navMenuOpen]);

  const wrapperStyles = {
    opacity: animateInSpring.active,
    transform: navSpring.x.to(x => `translateY(${x * 100}px)`),
  };

  const triggerStyles = {
    width: hoveringSpring.active.to(x => `calc((${x} * (100% - 14px - (${(mainSiteOverlay ? 1 : 0)} * 2px))) + 14px)`),
    height: hoveringSpring.active.to(x => `calc((${x} * (100% - 14px - (${(mainSiteOverlay ? 1 : 0)} * 2px))) + 14px)`),
  };

  const labelStyles = {
    opacity: mainSiteOverlay ? hoveringSpring.active : 0,
  };

  return (
    <a.div
      className={classNames(
        'interfaceTriggerWrapper',
        'backgroundShade-' + backgroundShade,
        { active },
        { mainSiteOverlay },
        { alignLeft: align === 'left' },
        { alignRight: align === 'right' },
      )}
      style={wrapperStyles}
      onMouseEnter={handleTriggerMouseEnter}
      onMouseLeave={handleTriggerMouseLeave}
    >
      <div className='label'>{title}</div>
      <a.div
        className='interfaceTrigger'
        style={triggerStyles}
      >
        <BlockLink to={url} title={title}>
          <a.div
            className='label'
            style={labelStyles}
          >
            {title}
          </a.div>
        </BlockLink>
        <div className='smallTrigger' />
      </a.div>
    </a.div>
  );
};

// Selects which state properties are merged into the component's props
const mapStateToProps = (state: IApplicationState, ownProps: IOwnProps): IStateProps => ({
  animateContentIn: fromUserInterface.getPageContentAnimatedIn(state),
  //animateContentIn: fromUserInterface.getNewPageAnimateContentIn(state),
  navMenuOpen: fromMainNavigation.getNavMenuOpen(state),
});

// Selects which action creators are merged into the component's props
const mapDispatchToProps: IDispatchProps = fromUserInterface.actionCreators;

// Wire up the React component to the Redux store
export const Trigger = connect<IStateProps, IDispatchProps, IOwnProps>
  (mapStateToProps, mapDispatchToProps)(InterfaceTriggerComponent);
