//import 'babel-polyfill';
import {  Reducer } from 'redux';
import { IApplicationState } from './index';

import { IIconTypes } from 'components/Icon/Icon';

import { createSelector } from 'reselect';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export type IBackgroundShade = 'light' | 'dark';

export interface IProjectImages {
  logoDark?: string;
  logoLight?: string;
  home?: string;
  logomarkBackground?: string;
  nextProjectBackground?: string;
  detailsHeroBackground?: string;
  detailsHeroFeaturedBackground?: string;
}

export interface IProjectOverview {
  backgroundShade: IBackgroundShade;
  titleColor: string;
  overview: string;
  role: string[];
  year: string;
}

export interface IProjectChallenge {
  backgroundShade: IBackgroundShade;
  backgroundColor: string;
  challenge: string;
}

export interface IBulletpoint {
  icon: IIconTypes;
  label: string;
}

export type IContentPaddingLevels = 'large' | 'medium' | 'small' | 'none';

export interface IBulletPoints {
  color: string;
  list: IBulletpoint[];
}

// ------------------------------------------------------------- Image Properties

export interface IImage {
  url: string;
  alt: string;
  heightRatio?: number;
}

// ------------------------------------------------------------- Video Properties

export interface IVideo {
  url: string;
  heightRatio?: number;
}

// ------------------------------------------------------------- Content Body Section - Image Board

export interface IContentImageBoardContent {
  type: 'image' | 'video';
  details: IImage | IVideo;
}

export type IContentImageBoardVariations =
  'threeScattered'
  | 'citiesOfTheFutureClips'
  | 'twoOnOne';

export interface IContentImageBoardSection {
  variation: IContentImageBoardVariations;
  content: IContentImageBoardContent[];
}

// ------------------------------------------------------------- Content Body Section - Image Slider

export interface IContentImageSliderDefaultItem extends IImage {
  //tileHeight: 'xs' | 's' | 'm' | 'l' | 'xl';
  tileWidth: 'xs' | 's' | 'm' | 'l' | 'xl';
  backgroundColor?: string;
  content?: JSX.Element;
}

export interface IContentImageSliderDefaultRow {
  images: IContentImageSliderDefaultItem[];
}

export interface IContentImageSliderDefault {
  rows: IContentImageSliderDefaultRow[];
}

export type IContentImageSliderVariations =
  'default';

export type IContentImageSliderVariationContent = IContentImageSliderDefault;

export interface IContentImageSliderSection {
  variation: IContentImageSliderVariations;
  content: IContentImageSliderVariationContent;
  dragHand: boolean;
  backgroundColor: string;
}

// ------------------------------------------------------------- Content Body Section - Image Reveal Slider

export interface IContentImageRevealSliderSectionImage {
  title: string;
  image: IImage;
}

export interface IContentImageRevealSliderSection {
  variation: 'default';
  leftImage: IContentImageRevealSliderSectionImage;
  rightImage: IContentImageRevealSliderSectionImage;
  backgroundColor: string;
}

// ------------------------------------------------------------- Content Body Section - Massive Scrolling Image Layout

export interface IContentMassiveScrollingImage extends IImage {
  width: string;
  imageOffset: string;
}

export interface IContentMassiveScrollingImages {
  angle: number;
  images: IContentMassiveScrollingImage[];
}

export type IContentMassiveScrollingImageVariations =
  'angledHorizontal';

export interface IContentMassiveScrollingImageSection {
  variation: IContentMassiveScrollingImageVariations;
  content: IContentMassiveScrollingImages;
}

// ------------------------------------------------------------- Content Body Section - Stepped Layout

export interface IContentSteppedLayoutParallaxTile {
  text: string;
  image: IImage;
  overlayTileColor: string;
}

export interface IContentSteppedLayoutSingleImage extends IImage {
  imageOffset: string;
}

export type IContentSteppedLayoutVariations =
  'singleImage'
  | 'parallaxTile';

export type IContentSteppedLayoutVariationListItem =
  IContentSteppedLayoutSingleImage
  | IContentSteppedLayoutParallaxTile;

export interface IContentSteppedLayoutSection {
  variation: IContentSteppedLayoutVariations;
  listItems: IContentSteppedLayoutVariationListItem[];
  backgroundImage: IImage;
  backgroundColor: string;
}

// ------------------------------------------------------------- Content Body Section - Timeline

export interface IContentTimelineBar {
  text: string;
  startStage: number;
  endStage: number;
  backgroundColor: string;
}

export interface IContentTimeline {
  bars: IContentTimelineBar[];
  lineColor: string;
  stageTextColor: string;
  backgroundColor: string;
}

export type IContentTimelineVariations =
  'default';

export interface IContentTimelineSection {
  variation: IContentTimelineVariations;
  content: IContentTimeline;
}

// ------------------------------------------------------------- Content Body Section - Brand Board

export interface IContentBrandBoardImages {
  darkLogomark: IImage;
  lightLogo: IImage;
}

export type IContentBrandBoardTypography = 'chapazaRegular' | 'latoRegular' | 'latoLight';
export type IContentBrandBoardFont = 'chapaza' | 'lato';
export type IContentBrandBoardWeight = 'regular' | 'bold' | 'italic' | 'boldItalic';

export interface IContentBrandBoardBrandColor {
  hexCode: string;
  shade: 'light' | 'dark';
}

export interface IContentBrandBoardJLRTitle {
  font: IContentBrandBoardFont;
  weights: IContentBrandBoardWeight[];
  exampleText: string;
}

export interface IContentBrandBoardJLRBody {
  font: IContentBrandBoardFont;
  exampleText: string;
}

export interface IContentBrandBoardJLR {
  brandColors: IContentBrandBoardBrandColor[];
  titleType: IContentBrandBoardJLRTitle;
  bodyType: IContentBrandBoardJLRBody;
}

export interface IContentBrandBoardPodiumValuations {
  images: IContentBrandBoardImages;
  brandColors: IContentBrandBoardBrandColor[];
  typography: IContentBrandBoardTypography[];
}

export type IContentBrandBoardVariations =
  'podiumValuations'
  | 'jlr';

export interface IContentBrandBoardSection {
  variation: IContentBrandBoardVariations;
  sectionContent:
  IContentBrandBoardJLR
  | IContentBrandBoardPodiumValuations;
}

// ------------------------------------------------------------- Content Body Section - Quotation

export interface IContentQuotationFullBleedImage extends IContentQuotationCommon {
  image: IImage;
  align: 'left' | 'right';
}

export type IContentQuotationDefault = IContentQuotationCommon;

export interface IContentQuotationCommon {
  quotationMarkColor: string;
  personLineColor: string;
  name: string;
  title: string;
  quotation: string;
}

export type IContentQuotationVariations =
  'default'
  | 'fullBleedImage';

export type IContentQuotationContent =
  IContentQuotationDefault
  | IContentQuotationFullBleedImage;

export interface IContentQuotationSection {
  variation: IContentQuotationVariations;
  content: IContentQuotationContent;
}

// ------------------------------------------------------------- Content Body Section - Single Large Image

export interface IContentSingleLargeImageSection {
  variation: 'default';
  image: IImage;
}

// ------------------------------------------------------------- Content Body Section - Overlapping Image Board

export interface IContentOverlappingImageBoardImageSpecific {
  rightOffset: string;
}

export interface IContentOverlappingImageBoardImage extends IImage, IContentOverlappingImageBoardImageSpecific { }

export interface IContentOverlappingImageBoardSection {
  variation: 'decreasingInSize';
  images: IContentOverlappingImageBoardImage[];
}

// ------------------------------------------------------------- Content Body Section - Full Bleed Image

export type IContentFullBleedImageVariations =
  'behind'
  | 'inline'
  | 'parallax';

export interface IContentFullBleedImageSection {
  variation: IContentFullBleedImageVariations;
  image: IImage;
  topMargin: string;
  bottomMargin: string;
}

// ------------------------------------------------------------- Content Body Section - Overlapping Images

export interface IContentOverlappingImagesSection {
  variation: 'default';
  image: IImage[];
}

// ------------------------------------------------------------- Content Body Section - Sticky Scrolling Image

export interface IContentStickyScrollingImageSection {
  variation: 'default';
  image: IImage;
}

// ------------------------------------------------------------- Content Body Section - Single Large Video

export interface IContentSingleLargeVideoSection {
  variation: 'default';
  video: IVideo;
}

// ------------------------------------------------------------- Content Body Section

export type IContentBodySectionTypes =
  'imageBoard'
  | 'imageSlider'
  | 'imageRevealSlider'
  | 'steppedLayout'
  | 'brandBoard'
  | 'quotation'
  | 'singleLargeImage'
  | 'massiveScrollingImage'
  | 'overlappingImageBoard'
  | 'overlappingImages'
  | 'stickyScrollingImage'
  | 'singleLargeVideo'
  | 'fullBleedImage'
  | 'timeline';

export interface IContentBodySection {
  type: IContentBodySectionTypes;
  position: 'inline' | 'side';
  bodyContent: IContentImageBoardSection
  | IContentImageSliderSection
  | IContentImageRevealSliderSection
  | IContentSteppedLayoutSection
  | IContentBrandBoardSection
  | IContentQuotationSection
  | IContentSingleLargeImageSection
  | IContentMassiveScrollingImageSection
  | IContentOverlappingImageBoardSection
  | IContentOverlappingImagesSection
  | IContentStickyScrollingImageSection
  | IContentSingleLargeVideoSection
  | IContentFullBleedImageSection
  | IContentTimelineSection;
}

// ------------------------------------------------------------- Content Title Section - Three Columns

export type IContentTitleThreeColumnVariations =
  'default'
  | 'inlineTitle';

export interface IContentTitleThreeColumnSection extends IContentTitleCommonSection {
  variation: IContentTitleThreeColumnVariations;
}

// ------------------------------------------------------------- Content Title Section - Default

export interface IContentTitleDefaultSection extends IContentTitleCommonSection {
  variation: 'default';
  bulletPoints: IBulletPoints;
  align: 'left' | 'right';
}

// ------------------------------------------------------------- Content Title Section - Common

export interface IContentTitleCommonSection {
  title: string;
  subtitle: string;
  description: string;
  topPadding: IContentPaddingLevels;
}

// ------------------------------------------------------------- Content Title Section

export type IContentTitleSectionTypes =
  'default'
  | 'threeColumn';

export interface IContentTitleSection {
  type: IContentTitleSectionTypes;
  position: 'inline' | 'side';
  titleContent: IContentTitleDefaultSection
  | IContentTitleThreeColumnSection;
}

// ------------------------------------------------------------- Content Title Section

export interface IContentBlock {
  titleSection: IContentTitleSection;
  bodySection: IContentBodySection[];
}

// ------------------------------------------------------------- Content Section

export type IBackgroundShadeTypes =
  'light'
  | 'dark';

export interface IContentSection {
  backgroundColor: string;
  backgroundImage: string;
  backgroundShade: IBackgroundShadeTypes;
  topPadding: IContentPaddingLevels;
  bottomPadding: IContentPaddingLevels;
  contentBlocks: IContentBlock[];
}

// ------------------------------------------------------------- Nav Section

export interface INavSection {
  title: string;
  contentSection: IContentSection[];
}

// ------------------------------------------------------------- Project Hero Section - Three Floating Screenshots

export interface IProjectHeroSectionThreeFloatingScreenshots {
  variation: 'default';
  images: IImage[];
}

// ------------------------------------------------------------- Project Hero Section - Fanned Document

export interface IProjectHeroSectionFannedDocument {
  variation: 'default';
  images: IImage[];
}

// ------------------------------------------------------------- Project Hero Section - Depth Parallax Image

export interface IProjectHeroSectionDepthParallaxImage {
  variation: 'default';
  images: IImage[];
}

// ------------------------------------------------------------- Project Hero Section

export type IProjectHeroSectionTypes =
  'depthParallaxImage'
  | 'threeFloatingScreenshots'
  | 'fannedDocument';

export type IProjectHeroSectionProperties =
  IProjectHeroSectionDepthParallaxImage
  | IProjectHeroSectionThreeFloatingScreenshots
  | IProjectHeroSectionFannedDocument;

export interface IProjectHeroSection {
  type: IProjectHeroSectionTypes;
  backgroundColor: string;
  backgroundShade: IBackgroundShade;
  pagePositionBarColor: string;
  properties: IProjectHeroSectionProperties;
}

// ------------------------------------------------------------- Project

export interface IProject {
  title: string;
  name: string;
  description: string;
  linkUrl: string;
  heroSection: IProjectHeroSection;
  projectOverview: IProjectOverview;
  projectChallenge: IProjectChallenge;
  projectContent: INavSection[];
  images: IProjectImages;
}

export interface IProjects {
  [name: string]: IProject;
}

export interface IProjectsState {
  projectDetails: IProjects;
}

export const projects: IProjects = {
  podiumValuations: {
    title: 'Redefining and bringing auction valuations into the modern era',
    name: 'Podium Valuations',
    description: 'We helped develop a fully fledged platform to allow people to get valuations from official auction houses from the comfort of their own homes.',
    linkUrl: 'podiumValuations',
    heroSection: {
      type: 'threeFloatingScreenshots',
      backgroundColor: '#1f0c29',
      backgroundShade: 'dark',
      pagePositionBarColor: '#622e7e',
      properties: {
        variation: 'default',
        images: [
          {
            url: 'podiumValuations/workDetailsHeroLeftScreenshot.png',
            alt: 'Item Details',
            heightRatio: 600 / 1140,
          },
          {
            url: 'podiumValuations/workDetailsHeroCentralScreenshot.png',
            alt: 'Auction Valuations',
            heightRatio: 800 / 1520,
          },
          {
            url: 'podiumValuations/workDetailsHeroRightScreenshot.png',
            alt: 'Service Pricing',
            heightRatio: 600 / 1140,
          },
        ],
      },
    },
    projectOverview: {
      backgroundShade: 'dark',
      titleColor: '#805f92',
      overview: 'Traditional auction houses haven’t adapted to the increasingly digital and mobile world that we now live in. With traditional auction houses, the need to transport large family heirlooms or furniture to the houses themselves was a risk for people, not to mention expensive, and put them off of getting their items valued out of curiosity. Podium Valuations was formed in an effort to solve this and drag auction valuations firmly into the present through an intuitive and seamless online platform. This would enable users to get professional valuations on whichever device they had to hand, be it their mobile on their way to work, or a laptop on their couch at home, as efficiently and as easily as possible.',
      role: [
        'Branding',
        'UX strategy',
        'UI design',
        'Visual exploration',
        'System development',
      ],
      year: '2014',
    },
    projectChallenge: {
      backgroundShade: 'light',
      backgroundColor: null,
      challenge: 'Podium Valuations came to us with a very simple idea; they wanted to make professional auction valuations available to the mass market. They desired a refined brand and an immersive valuation platform, that was easy to use for the consumer, yet sophisticated enough to give valuers the information they needed to return accurate valuations.',
    },
    projectContent: [
      {
        title: 'Our approach',
        contentSection: [
          {
            backgroundColor: '#fbfbfb',
            backgroundImage: null,
            backgroundShade: 'light',
            topPadding: 'medium',
            bottomPadding: 'medium',
            contentBlocks: [
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'Our approach to the project',
                    subtitle: 'Begin with the brand and everything else will follow',
                    description: 'We worked closely with the Podium Valuations team to better understand their target market and the vision that they wished to communicate across through their brand and online platform. Following this, we carried out a lot of research into the market and their potential users, and decided that the best way to progress would be to initially get a brand direction before working on the valuations platform. \n\n Because  the brand and the web platform were both so intrinsically linked to one another and the success of the proposition, we decided that we would work on both in tandem, to allow them to influence each other once we had an initial indication of the brand direction.',
                    bulletPoints: null,
                    align: 'left',
                    topPadding: 'medium',
                  },
                },
                bodySection: [
                  {
                    type: 'imageBoard',
                    position: 'inline',
                    bodyContent: {
                      variation: 'threeScattered',
                      content: [
                        {
                          type: 'image',
                          details: {
                            url: 'podiumValuations/workDetailsApproachAuctionHouse.jpg',
                            alt: 'An auction House',
                            heightRatio: 700 / 730,
                          },
                        },
                        {
                          type: 'image',
                          details: {
                            url: 'podiumValuations/workDetailsApproachCamera.jpg',
                            alt: 'Camera to be auctioned',
                            heightRatio: 800 / 730,
                          },
                        },
                        {
                          type: 'image',
                          details: {
                            url: 'podiumValuations/workDetailsApproachShip.jpg',
                            alt: 'A model ship to be auctioned',
                            heightRatio: 1200 / 730,
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'The brand',
        contentSection: [
          {
            backgroundColor: '#ffffff',
            backgroundImage: null,
            backgroundShade: 'light',
            topPadding: 'medium',
            bottomPadding: 'medium',
            contentBlocks: [
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'Our initial exploration',
                    subtitle: 'Building the brands vision',
                    description: 'We started off by generating a large number of possible options and with the help of the client slowly narrowed these down to the directions we felt best communicated the vision of the brand and service. Always keeping in the back of our minds the findings from our research and visual moodboards.',
                    bulletPoints: null,
                    align: 'right',
                    topPadding: 'none',
                  },
                },
                bodySection: [
                  {
                    type: 'imageSlider',
                    position: 'inline',
                    bodyContent: {
                      variation: 'default',
                      content: {
                        rows: [
                          {
                            images: [
                              {
                                tileWidth: 'xl',
                                backgroundColor: '#5C337A',
                                url: 'podiumValuations/workDetailsExplorationLogoSketches.jpg',
                                alt: 'Logo exploration with old-school pen and paper',
                                heightRatio: 680 / 1050,
                              },
                              {
                                tileWidth: 'xl',
                                backgroundColor: '#5C337A',
                                url: 'podiumValuations/workDetailsExplorationColours.jpg',
                                alt: 'Having a look at possible colour combinations for the brand',
                                heightRatio: 680 / 1050,
                              },
                              {
                                tileWidth: 'xl',
                                backgroundColor: '#5C337A',
                                url: 'podiumValuations/workDetailsExplorationDigitalLogos.jpg',
                                alt: 'Digitised versions of slightly more developed logo concepts',
                                heightRatio: 680 / 1050,
                              },
                            ],
                          },
                        ],
                      },
                      dragHand: false,
                      backgroundColor: '#fbfbfb',
                    },
                  },
                ],
              },
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'Simple but striking',
                    subtitle: 'The logo, colours and typefaces',
                    description: 'We arrived at a sans-serif logomark paired with a refined logotype for the main brand to keep the focus on the site content. This was paired with a deep purple to emphasise the level of reliability and quality of the valuation platform as well as adding a complementing yellow for areas that needed to catch the eye.',
                    bulletPoints: null,
                    align: 'left',
                    topPadding: 'none',
                  },
                },
                bodySection: [
                  {
                    type: 'brandBoard',
                    position: 'inline',
                    bodyContent: {
                      variation: 'podiumValuations',
                      sectionContent: {
                        images: {
                          darkLogomark: {
                            url: 'podiumValuations/brandBoardDarkLogomark.png',
                            alt: 'The final brand logo',
                            heightRatio: 200 / 150,
                          },
                          lightLogo: {
                            url: 'podiumValuations/brandBoardLightLogo.png',
                            alt: 'The final brand logo',
                            heightRatio: 440 / 200,
                          },
                        },
                        brandColors: [
                          {
                            hexCode: '#7b5397',
                            shade: 'dark',
                          },
                          {
                            hexCode: '#462155',
                            shade: 'dark',
                          },
                          {
                            hexCode: '#2f133b',
                            shade: 'dark',
                          },
                          {
                            hexCode: '#f8f8f8',
                            shade: 'light',
                          },
                          {
                            hexCode: '#f2f2f2',
                            shade: 'light',
                          },
                          {
                            hexCode: '#feb300',
                            shade: 'dark',
                          },
                        ],
                        typography: [
                          'chapazaRegular',
                          'latoRegular',
                          'latoLight',
                        ],
                      },
                    },
                  },
                  //{
                  //  type: 'quotation',
                  //  position: 'inline',
                  //  bodyContent: {
                  //    variation: 'default',
                  //    content: {
                  //      quotationMarkColor: '#944FC6',
                  //      personLineColor: '#3E2551',
                  //      name: 'Fahad Rathore',
                  //      title: 'Previous Client',
                  //      quotation: 'QQore is a full-service digital studio with a small but talented team that create delightful digital products, brands and experiences. With a unique approach guided by thoughtful design, we deliver identities and systems that thrill users and generate business results.',
                  //    },
                  //  },
                  //},
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'The design',
        contentSection: [
          {
            backgroundColor: '#161616',
            backgroundImage: null,
            backgroundShade: 'dark',
            topPadding: 'medium',
            bottomPadding: 'medium',
            contentBlocks: [
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'Laying the foundations',
                    subtitle: 'Setting out the site structure and starting off with sketches',
                    description: "We start off with sketches on most projects. This one was no different. We started by quickly getting any ideas we had down on paper, be it abstract ideas or more detailed layouts for the main stages of the valuations procedure. Doing this on paper meant we weren't worrying about using the right tools, just pencil and paper.",
                    bulletPoints: null,
                    align: 'left',
                    topPadding: 'none',
                  },
                },
                bodySection: [
                  {
                    type: 'singleLargeImage',
                    position: 'inline',
                    bodyContent: {
                      variation: 'default',
                      image: {
                        url: 'podiumValuations/workDetailsOverviewOfWireframes.png',
                        alt: 'Initial sketches of various screens of the system',
                        heightRatio: 1000 / 2360,
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            backgroundColor: '#101010',
            backgroundImage: null,
            backgroundShade: 'dark',
            topPadding: 'medium',
            bottomPadding: 'none',
            contentBlocks: [
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'Focusing on what’s important',
                    subtitle: 'Getting it right with iterative wireframing and prototyping',
                    description: "We built out the sketches into high fidelity wireframes and prototyped them in Invision. We tested each of the screens within the context of the application as a whole in the clickable prototype, and gained useful feedback and insights that normally would not be noticed from simple screen printouts.",
                    bulletPoints: null,
                    align: 'right',
                    topPadding: 'none',
                  },
                },
                bodySection: [
                  {
                    type: 'overlappingImageBoard',
                    position: 'inline',
                    bodyContent: {
                      variation: 'decreasingInSize',
                      images: [
                        {
                          url: 'podiumValuations/workDetailsPrototypeSmall.png',
                          alt: 'Sketch of user interface',
                          heightRatio: 926 / 758,
                          rightOffset: '0',
                        },
                        {
                          url: 'podiumValuations/workDetailsPrototypeMedium.jpg',
                          alt: 'Low fidelity version of the user interface',
                          heightRatio: 1136 / 948,
                          rightOffset: '20vw',
                        },
                        {
                          url: 'podiumValuations/workDetailsPrototypeLarge.jpg',
                          alt: 'Accurate grayscale website design',
                          heightRatio: 1395 / 1138,
                          rightOffset: null,
                        },
                      ],
                    },
                  },
                ],
              },
            ],
          },
          {
            backgroundColor: '#101010',
            backgroundImage: 'podiumValuations/workDetailsDesignBackground.jpg',
            backgroundShade: 'dark',
            topPadding: 'medium',
            bottomPadding: 'none',
            contentBlocks: [
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'A splash of colour',
                    subtitle: 'Bringing the platform together with clever design flourishes',
                    description: "Focusing on the structure and layout of the interface in the earlier stages, iterating through and optimising the user experience, meant that adding colour only enhanced the design. This is where developing both the brand and the site concurrently really paid dividends, we were able to chop and change as we wanted whilst keeping the brand identity consistent throughout.",
                    bulletPoints: null,
                    align: 'left',
                    topPadding: 'medium',
                  },
                },
                bodySection: [
                  {
                    type: 'fullBleedImage',
                    position: 'side',
                    bodyContent: {
                      variation: 'behind',
                      image: {
                        url: 'podiumValuations/workDetailsDesignImage.png',
                        alt: 'A large board of images showing off the website designs with an injection of colour',
                        heightRatio: 2920 / 2560,
                      },
                      topMargin: null,
                      bottomMargin: null,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'End user experience',
        contentSection: [
          {
            backgroundColor: '#1F0C29',
            backgroundImage: null,
            backgroundShade: 'dark',
            topPadding: 'medium',
            bottomPadding: 'medium',
            contentBlocks: [
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'Bringing everything to life',
                    subtitle: 'A better portal from which to value loved possessions',
                    description: "The aim behind Podium Valuations was to make auction valuations as simple and as easy as possible. The homepage for the site would be the main entrypoint for potential customers into the valuations process. What we ended up settling on was a design that focused on the benefits users would get with a clear call to action in the footer.",
                    bulletPoints: {
                      color: '#cb83ef',
                      list: [
                        {
                          icon: 'heroSection',
                          label: 'Clean hero explaining the concept',
                        },
                        {
                          icon: 'processIntro',
                          label: 'Simple intro to the process',
                        },
                        {
                          icon: 'stickyNavigation',
                          label: 'Sticky navigation throughout',
                        },
                        {
                          icon: 'valuation',
                          label: 'Emphasis on the valuations interface',
                        },
                        {
                          icon: 'feature',
                          label: 'Extra features grid',
                        },
                        {
                          icon: 'cta',
                          label: 'Multiple CTA touchpoints',
                        },
                      ],
                    },
                    align: 'left',
                    topPadding: 'medium',
                  },
                },
                bodySection: [
                  {
                    type: 'stickyScrollingImage',
                    position: 'side',
                    bodyContent: {
                      variation: 'default',
                      image: {
                        url: 'podiumValuations/workDetailsScreenshotHomepage.jpg',
                        alt: 'The homepage for the Podium Valuations website',
                        heightRatio: 2660 / 960,
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            backgroundColor: '#2D0E3D',
            backgroundImage: null,
            backgroundShade: 'dark',
            topPadding: 'medium',
            bottomPadding: 'medium',
            contentBlocks: [
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'A bird’s eye view',
                    subtitle: 'An attractive but informative overview of all your items',
                    description: "It was important that the items dashboard from the average person's perspective was simple and showed all the items that had been submitted for valuations as well as their status within the valuation process in a clean manner. Our focus was to minimise the information being shown whilst keeping the experience informative and intuitive.",
                    bulletPoints: {
                      color: '#cb83ef',
                      list: [
                        {
                          icon: 'itemGrid',
                          label: 'Grid of items submitted',
                        },
                        {
                          icon: 'sorting',
                          label: 'Filtering and sorting',
                        },
                        {
                          icon: 'itemCheckbox',
                          label: 'Multiple item management options',
                        },
                        {
                          icon: 'itemStatus',
                          label: 'Immediately visible item statuses',
                        },
                        {
                          icon: 'imagery',
                          label: 'A focus on imagery',
                        },
                        {
                          icon: 'account',
                          label: 'Account management and navigation',
                        },
                      ],
                    },
                    align: 'right',
                    topPadding: 'medium',
                  },
                },
                bodySection: [
                  {
                    type: 'stickyScrollingImage',
                    position: 'side',
                    bodyContent: {
                      variation: 'default',
                      image: {
                        url: 'podiumValuations/workDetailsScreenshotUserValuations.jpg',
                        alt: 'The user dashboard showing all their items and their statuses within the valuations process',
                        heightRatio: 1176 / 960,
                      },
                    },
                  },
                ],
              },
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'Straightforward and simple',
                    subtitle: "Putting in your item’s details are surprisingly easy",
                    description: "We made sure that the listing procedure for items into the system was attractive in and of itself, we'd had enough of forms being a necessary chore for users. This aim drove everything in the pages design, from the way the input elements behaved, to the way that images were uploaded and inserted into the form, everything was custom developed to reinforce the personalised high end feel you would expect from an auction house.",
                    bulletPoints: {
                      color: '#cb83ef',
                      list: [
                        {
                          icon: 'inPageNavigation',
                          label: 'Seamless in-page navigation',
                        },
                        {
                          icon: 'formElement',
                          label: 'Custom designed form elements',
                        },
                        {
                          icon: 'uploadImage',
                          label: 'Instant image uploads',
                        },
                        {
                          icon: 'optimise',
                          label: 'Automatic image optimisation',
                        },
                        {
                          icon: 'paymentProvider',
                          label: 'Payment providers integration',
                        },
                        {
                          icon: 'notification',
                          label: 'Live notifications shade',
                        },
                      ],
                    },
                    align: 'right',
                    topPadding: 'medium',
                  },
                },
                bodySection: [
                  {
                    type: 'stickyScrollingImage',
                    position: 'side',
                    bodyContent: {
                      variation: 'default',
                      image: {
                        url: 'podiumValuations/workDetailsScreenshotUserNewValuation.jpg',
                        alt: 'The user interface for inputting a new item into the system for a potential customer',
                        heightRatio: 1375 / 960,
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: "Auction house's dashboard",
        contentSection: [
          {
            backgroundColor: '#1F0C29',
            backgroundImage: null,
            backgroundShade: 'dark',
            topPadding: 'medium',
            bottomPadding: 'medium',
            contentBlocks: [
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'A single page that does it all',
                    subtitle: 'Giving auction houses all of the details that they need',
                    description: "Whether auction houses had queries about the item before submitting a valuation, or if they needed to communicate with the owner to make arrangements after winning their business, the long scrolling page gives them these capabilities and more. We designed the whole experience to be concise from the business side whilst still being graceful and a pleasure to use.",
                    bulletPoints: {
                      color: '#cb83ef',
                      list: [
                        {
                          icon: 'question',
                          label: 'Instantly ask queries',
                        },
                        {
                          icon: 'inlineValuation',
                          label: 'Adaptive inline valuations',
                        },
                        {
                          icon: 'multipleImages',
                          label: 'Emphasis on the images',
                        },
                        {
                          icon: 'animation',
                          label: 'Delightful guiding animations',
                        },
                        {
                          icon: 'chat',
                          label: 'Real-time chat',
                        },
                        {
                          icon: 'transactionVerification',
                          label: 'Transaction outcome verification',
                        },
                      ],
                    },
                    align: 'left',
                    topPadding: 'medium',
                  },
                },
                bodySection: [
                  {
                    type: 'stickyScrollingImage',
                    position: 'side',
                    bodyContent: {
                      variation: 'default',
                      image: {
                        url: 'podiumValuations/workDetailsScreenshotAuctionValueItem.jpg',
                        alt: "The auction houses detailed view of an item where they can manage their interactions and submit valuations all from one central location",
                        heightRatio: 2348 / 960,
                      },
                    },
                  },
                ],
              },
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'Aware and in control',
                    subtitle: 'Browse and monitor items that need to be acted upon',
                    description: "The hub for the auction houses was designed to show more detail about each item whilst separating them out into groupings to make it clear which items were a priority and where they stood for the auction house. This includes sections within each items tile for their notifications, valuations as well as unique ids that could be quoted internally to instantly identify items rather than having to use more vague titles.",
                    bulletPoints: {
                      color: '#cb83ef',
                      list: [
                        {
                          icon: 'feed',
                          label: 'Live feed of items',
                        },
                        {
                          icon: 'denseLayout',
                          label: 'A clean but dense layout',
                        },
                        {
                          icon: 'highlights',
                          label: 'Easily glanceable highlights',
                        },
                        {
                          icon: 'prioritisation',
                          label: 'Prioritisation of acted on items',
                        },
                        {
                          icon: 'contextNotification',
                          label: 'Context-aware notifications',
                        },
                      ],
                    },
                    align: 'left',
                    topPadding: 'medium',
                  },
                },
                bodySection: [
                  {
                    type: 'stickyScrollingImage',
                    position: 'side',
                    bodyContent: {
                      variation: 'default',
                      image: {
                        url: 'podiumValuations/workDetailsScreenshotAuctionValuations.jpg',
                        alt: "The auction houses dashboard showing the statuses of all their items and the new ones for them to deal with",
                        heightRatio: 1762 / 960,
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: "A sneak peek",
        contentSection: [
          {
            backgroundColor: '#2D0E3D',
            backgroundImage: null,
            backgroundShade: 'dark',
            topPadding: 'medium',
            bottomPadding: 'medium',
            contentBlocks: [
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'A delightful user experience',
                    subtitle: 'From start to finish',
                    description: "The new Podium Valuations website brings together quite a complex user flow and a traditional industry into a refined and cohesive system to make auction valuations as easy as listing items on eBay.",
                    bulletPoints: null,
                    align: 'left',
                    topPadding: 'none',
                  },
                },
                bodySection: [
                  {
                    type: 'singleLargeVideo',
                    position: 'inline',
                    bodyContent: {
                      variation: 'default',
                      video: {
                        url: 'podiumValuations/overviewUserExperienceVideo.mp4',
                        heightRatio: 1080 / 1900,
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    images: null,
  },
  citiesOfTheFuture: {
    title: 'Crafting the vision for a city pitched to the King of Dubai',
    name: 'Cities of the Future',
    description: 'We were drafted in to create a number of pitch materials for the team, including the company brand, brochure, accompanying presentation and visionary video.',
    linkUrl: 'citiesOfTheFuture',
    heroSection: {
      type: 'depthParallaxImage',
      backgroundColor: '#040E1A',
      backgroundShade: 'dark',
      pagePositionBarColor: '#0f315d',
      properties: {
        variation: 'default',
        images: [
          {
            url: 'citiesOfTheFuture/workDetailsHeroFeaturedBackground.jpg',
            alt: 'Dubai sky',
            heightRatio: 1400 / 2860,
          },
          {
            url: 'citiesOfTheFuture/workDetailsHeroCityLayer.png',
            alt: 'Dubai city',
            heightRatio: 1400 / 2860,
          },
          {
            url: 'citiesOfTheFuture/workDetailsHeroCityFrontLayer.png',
            alt: 'Dubai city foreground',
            heightRatio: 1400 / 2860,
          },
          {
            url: 'citiesOfTheFuture/workDetailsHeroCloudsLayer.png',
            alt: 'Dubai clouds/mist',
            heightRatio: 1400 / 2860,
          },
        ],
      },
    },
    projectOverview: {
      backgroundShade: 'dark',
      titleColor: '#BFA890',
      overview: 'Cities previously designed to a particular size have generationally doubled and tripled as urbanization stepped up in gears, leaving services bursting at the seams, unable to cope with surges in demand. As a species, we face the tremendous challenges of climate change, environmental degradation, water and food security, and the need to balance sustainability against the need for commerce and economic activity. It is through striving to innovate solutions to a number of these problems, that the Cities of the Future team approached us with their dream for a new city. Their passion and confidence in their dream inspired us, and we loved jumping on board to help them with our design chops and worked to communicate their proposition across a multitude of brand touchpoints.',
      role: [
        'Branding',
        'Creative direction',
        'Visual exploration',
        'Digital presentation',
        'Brochure design',
        'Video production',
      ],
      year: '2013',
    },
    projectChallenge: {
      backgroundShade: 'dark',
      backgroundColor: '#02080f',
      challenge: 'We agreed to partner up with the Cities of the Future team and use our expertise to convey their vision to their audience in a manner that made even the most technical details easy to understand, through a variety of sensory experiences as well as inspiring visuals. This involved the creation of several brand materials, including the logo, pitch, brochure and a visionary video to create a deeper human connection.',
    },
    projectContent: [
      {
        title: "A tight timeline",
        contentSection: [
          {
            backgroundColor: '#040c17',
            backgroundImage: null,
            backgroundShade: 'dark',
            topPadding: 'medium',
            bottomPadding: 'medium',
            contentBlocks: [
              {
                titleSection: {
                  type: 'threeColumn',
                  position: 'inline',
                  titleContent: {
                    variation: 'inlineTitle',
                    title: 'A tight timeline',
                    subtitle: 'Putting our cards on the table from day one',
                    description: 'We knew that the best way to finish this project in the constrained timeline we were given would be to sit down with the Cities of the Future team from the very beginning of the project and invest some time laying out the major stages and milestones for the project. This helped us to define an internal timeline and highlight the critical path of failure for the project, or in layman’s terms, the tasks we could not afford to overrun on in order to meet our hard deadline. This meant we could effectively allocate resources and responsibilities, amongst the combined team, according to the priorities of the tasks and whether or not they had some flexibility. The time spent doing this paid massive dividends in the end, and meant we were able to deliver everything on time without compromising on our high standards for the work throughout the entirety of the project.',
                    topPadding: 'none',
                  },
                },
                bodySection: [
                  {
                    type: 'timeline',
                    position: 'inline',
                    bodyContent: {
                      variation: 'default',
                      content: {
                        bars: [
                          {
                            text: 'Branding',
                            startStage: 1,
                            endStage: 2,
                            backgroundColor: '#a13434',
                          },
                          {
                            text: 'Brochure content compilation',
                            startStage: 2,
                            endStage: 3,
                            backgroundColor: '#9334a1',
                          },
                          {
                            text: 'Brochure creation',
                            startStage: 3,
                            endStage: 7,
                            backgroundColor: '#871b97',
                          },
                          {
                            text: 'Video research',
                            startStage: 2,
                            endStage: 3,
                            backgroundColor: '#3460a1',
                          },
                          {
                            text: 'Video storyboarding and scripting',
                            startStage: 3,
                            endStage: 5,
                            backgroundColor: '#255397',
                          },
                          {
                            text: 'Professional voiceover',
                            startStage: 5,
                            endStage: 6,
                            backgroundColor: '#2a4b87',
                          },
                          {
                            text: 'Initial video timeline',
                            startStage: 5,
                            endStage: 7,
                            backgroundColor: '#1a4592',
                          },
                          {
                            text: 'Video production and finalisation',
                            startStage: 8,
                            endStage: 10,
                            backgroundColor: '#0f377f',
                          },
                        ],
                        lineColor: '#060e18',
                        stageTextColor: '#081b32',
                        backgroundColor: '#02080f',
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: "The brand",
        contentSection: [
          {
            backgroundColor: '#02080f',
            backgroundImage: null,
            backgroundShade: 'dark',
            topPadding: 'medium',
            bottomPadding: 'none',
            contentBlocks: [
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'Communicating the vision',
                    subtitle: 'Building it right into the brand',
                    description: 'Our first priority was to do some research and explore the proposition of the new city further so that any decisions we made on the direction for our designs were guided by the ethos and main tenets of the project. We wanted to ensure that the city constantly informed our choices with regards to the final deliverables, and through collaboration with the wider team we were able to design a visual identity that we all agreed fit the ultimate vision for the new city.',
                    bulletPoints: null,
                    align: 'right',
                    topPadding: 'none',
                  },
                },
                bodySection: [
                  {
                    type: 'imageSlider',
                    position: 'inline',
                    bodyContent: {
                      variation: 'default',
                      content: {
                        rows: [
                          {
                            images: [
                              {
                                tileWidth: 'xl',
                                backgroundColor: '#325379',
                                url: 'citiesOfTheFuture/workDetailsExplorationBrand.jpg',
                                alt: 'asgsagsagas',
                                heightRatio: 680 / 1050,
                              },
                              {
                                tileWidth: 'xl',
                                backgroundColor: '#325379',
                                url: 'citiesOfTheFuture/workDetailsLogoIteration.jpg',
                                alt: 'asgsagsagas',
                                heightRatio: 680 / 1050,
                              },
                              {
                                tileWidth: 'xl',
                                backgroundColor: '#325379',
                                url: 'citiesOfTheFuture/workDetailsLogoVectors.jpg',
                                alt: null,
                                heightRatio: 680 / 1050,
                              },
                            ],
                          },
                        ],
                      },
                      dragHand: false,
                      backgroundColor: '#05111f',
                    },
                  },
                ],
              },
            ],
          },
          {
            backgroundColor: '#101010',
            backgroundImage: null,
            backgroundShade: 'dark',
            topPadding: 'none',
            bottomPadding: 'none',
            contentBlocks: [
              {
                titleSection: null,
                bodySection: [
                  {
                    type: 'fullBleedImage',
                    position: 'inline',
                    bodyContent: {
                      variation: 'parallax',
                      image: {
                        url: 'citiesOfTheFuture/workDetailsTheFinalLogo.jpg',
                        alt: 'The final brand logo that we decided upon',
                        heightRatio: 1800 / 2560,
                      },
                      topMargin: null,
                      bottomMargin: null,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: "The brochure",
        contentSection: [
          {
            backgroundColor: '#fff',
            backgroundImage: null,
            backgroundShade: 'light',
            topPadding: 'medium',
            bottomPadding: 'none',
            contentBlocks: [
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'A brochure worthy of it all',
                    subtitle: 'Designed to do so much more than just convey information',
                    description: 'We were asked to create a brochure that would accompany the teams pitch for the new city. And as it would serve as a lingering reminder of the proposition after the pitch itself, it quickly became apparent that this had the potential to massively influence the decision on whether the project would be funded. \n\n ## Iterating on the content and making it work \n\n We began, as always, by working on making sure that the brochure’s content was the right tone for the proposition, and that it all, once collated and ordered, told a single cohesive story. We went through multiple revision with the Cities of the Future team and slowly but surely made progress until we reached a point we were happy we could work from.',
                    bulletPoints: null,
                    align: 'left',
                    topPadding: 'none',
                  },
                },
                bodySection: [
                  {
                    type: 'singleLargeImage',
                    position: 'inline',
                    bodyContent: {
                      variation: 'default',
                      image: {
                        url: 'citiesOfTheFuture/workDetailsContentExploration.jpg',
                        alt: null,
                        heightRatio: 940 / 2360,
                      },
                    },
                  },
                ],
              },
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: null,
                    subtitle: null,
                    description: '## Pairing images with the right colours for them \n\n The guidelines we were given for the purpose of the brochure was to focus on ensuring it conveyed a feeling for the vision of the project rather than a document that passed across technical details as important as they are. That’s why we decided to pair poignant images with more muted colours throughout the brochure to tell the story together with the literary contents.',
                    bulletPoints: null,
                    align: 'left',
                    topPadding: 'none',
                  },
                },
                bodySection: [
                  {
                    type: 'steppedLayout',
                    position: 'inline',
                    bodyContent: {
                      variation: 'parallaxTile',
                      listItems: [
                        {
                          text: '### We started by collecting all the images we thought would work well together \n\n Our aim was to get the attention of the readers with large images that fit the theme of the proposition whilst still reinforcing the overall message of the brochure. We pulled together a shortlist of images and paired them with the section of content we felt was most appropriate for them. The ideas behind our choices was to create a human connection between the content and the people reading whilst still retaining the feel of the modern metropolis that was being proposed in the background.',
                          image: {
                            url: 'citiesOfTheFuture/workDetailsPairedImagesDubaiStreetline.jpg',
                            alt: 'The Dubai streetline',
                            heightRatio: 1,
                          },
                          overlayTileColor: '#0d515a',
                        },
                        {
                          text: '### We pulled out the dominant colours from the images \n\n Doing so allowed us to build multiple thematic elements for various spread designs that fit in cohesively with the associated image. This meant the context of the image was felt throughout the spread and outside the bounds of the image itself, whilst having the added benefit of retaining the focus on the content of the brochure. ',
                          image: {
                            url: 'citiesOfTheFuture/workDetailsPairedImagesHongKongSkyline.jpg',
                            alt: 'The Hong Kong skyline',
                            heightRatio: 1,
                          },
                          overlayTileColor: '#5b2f5b',
                        },
                        {
                          text: '### And then mellowed some of the more vibrant tones \n\n Some of the images’ most dominant colours were incredibly bright, and when paired with any form of content, removed all focus from the message of the spread to the images themselves. For these we toned down the colours and normalised them across the brochure to make them fit better together as a single consistent scheme. We made a conscious decision to maintain an eclectic mix of colour throughout the brochure as we felt it served as a fitting reminder of the vibrant makeup of most cities.',
                          image: {
                            url: 'citiesOfTheFuture/workDetailsPairedImagesDubaiSkyline.jpg',
                            alt: 'The Dubai skyline',
                            heightRatio: 1,
                          },
                          overlayTileColor: '#143d70',
                        },
                      ],
                      backgroundImage: {
                        url: null,
                        alt: null,
                        heightRatio: 2333 / 2560,
                      },
                      backgroundColor: '#fbfbfb',
                    },
                  },
                ],
              },
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: null,
                    subtitle: null,
                    description: '## Letting the pages take shape as organically as possible \n\n We quickly decided that we wanted to infuse the brochure with a subtle aura of sophistication to give readers confidence in the team, as well as to make readers want to turn the pages through to the end. Our priority was to ensure that readers would flick through to get a sense for the project and the ultimate vision, whilst still taking away the most important information about the project with them.',
                    bulletPoints: null,
                    align: 'left',
                    topPadding: 'none',
                  },
                },
                bodySection: [
                  {
                    type: 'singleLargeImage',
                    position: 'inline',
                    bodyContent: {
                      variation: 'default',
                      image: {
                        url: 'citiesOfTheFuture/workDetailsBuildingPageLayouts.jpg',
                        alt: null,
                        heightRatio: 940 / 2360,
                      },
                    },
                  },
                ],
              },
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: null,
                    subtitle: null,
                    description: '## What could be better than images? Enormous images. \n\n Because the project’s vision was to create a new city, one which built on top of existing technology segregated across various cities all over the world, the only way we could convey the vision was to try to balance the content alongside large images so that the content would become associated by proximity and stick that little bit longer in the mind. And if we’re using images, why not go all out?',
                    bulletPoints: null,
                    align: 'right',
                    topPadding: 'none',
                  },
                },
                bodySection: [
                  {
                    type: 'massiveScrollingImage',
                    position: 'inline',
                    bodyContent: {
                      variation: 'angledHorizontal',
                      content: {
                        angle: 15.86,
                        images: [
                          {
                            url: 'citiesOfTheFuture/workDetailsMassiveCitySpread.jpg',
                            alt: 'A spread showing the different cities that had been analysed',
                            heightRatio: 1394 / 3897,
                            width: '3897px',
                            imageOffset: '0',
                          },
                          {
                            url: 'citiesOfTheFuture/workDetailsMassiveRoadSpread.jpg',
                            alt: 'A view of the road at night from the streetside',
                            heightRatio: 1394 / 3897,
                            width: '3897px',
                            imageOffset: '-0.5',
                          },
                          {
                            url: 'citiesOfTheFuture/workDetailsMassiveSkylineSpread.jpg',
                            alt: 'The skyline of Abu Dhabi spread',
                            heightRatio: 1394 / 3897,
                            width: '3897px',
                            //imageOffset: '-100%',
                            imageOffset: '-1',
                          },
                        ],
                      },
                    },
                  },
                ],
              },
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: null,
                    subtitle: null,
                    description: '## And a few more of the spreads that we came up with \n\n Our aim was to balance the projects content across various spreads to consciously control the rate at which readers would be required to absorb information. This would allow use to subtly influence how much of the projects message would be retained whilst still ending up on final design for the brochure which was extremely consistent yet beautiful.',
                    bulletPoints: null,
                    align: 'left',
                    topPadding: 'none',
                  },
                },
                bodySection: [
                  {
                    type: 'imageSlider',
                    position: 'inline',
                    bodyContent: {
                      variation: 'default',
                      content: {
                        rows: [
                          {
                            images: [
                              {
                                url: 'citiesOfTheFuture/workDetailsSpreadTransport.jpg',
                                alt: 'A spread showing the various transport plans for the new city',
                                heightRatio: 1394 / 3897,
                                tileWidth: 'm',
                              },
                              {
                                url: 'citiesOfTheFuture/workDetailsSpreadLevels.jpg',
                                alt: 'The different service levels throughout the city',
                                heightRatio: 1394 / 3897,
                                tileWidth: 'm',
                              },
                            ],
                          },
                          {
                            images: [
                              {
                                url: 'citiesOfTheFuture/workDetailsSpreadFrontCover.jpg',
                                alt: 'The brochures front cover',
                                heightRatio: 1394 / 1949,
                                tileWidth: 'xs',
                              },
                              {
                                url: 'citiesOfTheFuture/workDetailsSpreadEducation.jpg',
                                alt: 'Education options for the city as well as implementations',
                                heightRatio: 1394 / 3897,
                                tileWidth: 'm',
                              },
                              {
                                url: 'citiesOfTheFuture/workDetailsSpreadSecurity.jpg',
                                alt: 'How the city fits together with food and water security',
                                heightRatio: 1394 / 3897,
                                tileWidth: 'm',
                              },
                            ],
                          },
                          {
                            images: [
                              {
                                url: 'citiesOfTheFuture/workDetailsSpreadSolutions.jpg',
                                alt: 'How everything comes together',
                                heightRatio: 1394 / 3897,
                                tileWidth: 'm',
                              },
                              {
                                url: 'citiesOfTheFuture/workDetailsSpreadEnergy.jpg',
                                alt: 'How the city will generate its own energy',
                                heightRatio: 1394 / 3897,
                                tileWidth: 'm',
                              },
                            ],
                          },
                        ],
                      },
                      dragHand: false,
                      backgroundColor: '#fbfbfb',
                    },
                  },
                ],
              },
            ],
          },
          {
            backgroundColor: '#101010',
            backgroundImage: null,
            backgroundShade: 'dark',
            topPadding: 'none',
            bottomPadding: 'none',
            contentBlocks: [
              {
                titleSection: null,
                bodySection: [
                  {
                    type: 'quotation',
                    position: 'inline',
                    bodyContent: {
                      variation: 'fullBleedImage',
                      content: {
                        quotationMarkColor: '#b7ceed',
                        personLineColor: '#9eb1cd',
                        name: 'Fahad Rathore',
                        title: 'Previous Client',
                        quotation: 'QQore is a full-service digital studio with a small but talented team that create delightful digital products, brands and experiences. With a unique approach guided by thoughtful design, we deliver identities and systems that thrill users and generate business results.',
                        image: {
                          url: 'citiesOfTheFuture/workDetailsTheKualaLampurSkyline.jpg',
                          alt: 'The Kuala Lamput skyline in Malaysia',
                          heightRatio: 1800 / 2560,
                        },
                        align: 'right',
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: "The video",
        contentSection: [
          {
            backgroundColor: '#161616',
            backgroundImage: null,
            backgroundShade: 'dark',
            topPadding: 'medium',
            bottomPadding: 'none',
            contentBlocks: [
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'Worth a million words?',
                    subtitle: 'Channelling the excitement for the project through the video',
                    description: 'The final piece of the puzzle for this project was getting the video right. It needed to present some of the technical innovations of the new city whilst selling the idea behind the project as well. \n\n ## Starting out by nailing down the style we wanted \n\n We decided the best way to build a meaningful journey for the viewer was for the video to prioritise an inspiring core message alongside several accompanying clips that would reinforce it. We then laid out our initial storyboard and developed it with the team through multiple iterations; until we arrived on the one we felt fulfilled all our opening criteria as well as working well with the other associated brand materials.',
                    bulletPoints: null,
                    align: 'left',
                    topPadding: 'none',
                  },
                },
                bodySection: [
                  {
                    type: 'imageBoard',
                    position: 'inline',
                    bodyContent: {
                      variation: 'citiesOfTheFutureClips',
                      content: [
                        {
                          type: 'image',
                          details: {
                            url: 'citiesOfTheFuture/workDetailsTheKualaLampurSkyline.jpg',
                            alt: null,
                            heightRatio: 800 / 1160,
                          },
                        },
                        {
                          type: 'video',
                          details: {
                            url: 'citiesOfTheFuture/Sunset.mp4',
                            heightRatio: 1,
                          },
                        },
                        {
                          type: 'video',
                          details: {
                            url: 'citiesOfTheFuture/Scuba.mp4',
                            heightRatio: 600 / 910,
                          },
                        },
                        {
                          type: 'video',
                          details: {
                            url: 'citiesOfTheFuture/Road.mp4',
                            heightRatio: 500 / 900,
                          },
                        },
                        {
                          type: 'video',
                          details: {
                            url: 'citiesOfTheFuture/Lab.mp4',
                            heightRatio: 450 / 700,
                          },
                        },
                        {
                          type: 'video',
                          details: {
                            url: 'citiesOfTheFuture/Cactus.mp4',
                            heightRatio: 650 / 550,
                          },
                        },
                        {
                          type: 'video',
                          details: {
                            url: 'citiesOfTheFuture/MotherDaughter.mp4',
                            heightRatio: 700 / 1150,
                          },
                        },
                        {
                          type: 'video',
                          details: {
                            url: 'citiesOfTheFuture/Timeline.mp4',
                            heightRatio: 500 / 1200,
                          },
                        },
                        {
                          type: 'video',
                          details: {
                            url: 'citiesOfTheFuture/NightCity.mp4',
                            heightRatio: 650 / 850,
                          },
                        },
                      ],
                    },
                  },
                ],
              },
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: null,
                    subtitle: null,
                    description: '## Laying out the story that we wanted to tell \n\n We wanted to make the video as memorable as possible and decided the best way to do this would be to create emotional links between the visionary concept of the city and the story of a family going about their daily lives. And because we developed the script alongside the storyboard, naturally, as time progressed, we found it grew organically and told the teams story in a much more human way.',
                    bulletPoints: null,
                    align: 'left',
                    topPadding: 'medium',
                  },
                },
                bodySection: [
                  {
                    type: 'fullBleedImage',
                    position: 'inline',
                    bodyContent: {
                      variation: 'inline',
                      image: {
                        url: 'citiesOfTheFuture/workDetailsVideoTrack.png',
                        alt: 'The planned audio track for the Cities Of The Future video',
                        heightRatio: 2237 / 2560,
                      },
                      topMargin: '-34.765625vw',
                      bottomMargin: '-32vw',
                    },
                  },
                ],
              },
            ],
          },
          {
            backgroundColor: 'transparent',
            backgroundImage: 'citiesOfTheFuture/workDetailsVideoTrackBackground.png',
            backgroundShade: 'dark',
            topPadding: 'medium',
            bottomPadding: 'medium',
            contentBlocks: [
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: null,
                    subtitle: null,
                    description: '## Making sure the words are felt as they come off of the paper \n\n Pulling the entire video together was the final voiceover. We knew how important it was to do justice to the script by ensuring that the voiceover had the gravitas it needed to correctly convey the vision for the city and the entire project itself. There’s always the balance that needs to be found between fitting in more informational content and making the video digestible, but we got there in the end. \n\n The end result, and the way in which it emphasises the visuals and the videos story, really justifies the amount of time that was spent getting it just right.',
                    bulletPoints: null,
                    align: 'right',
                    topPadding: 'none',
                  },
                },
                bodySection: [],
              },
            ],
          },
          {
            backgroundColor: '#02060d',
            backgroundImage: null,
            backgroundShade: 'dark',
            topPadding: 'medium',
            bottomPadding: 'medium',
            contentBlocks: [
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: null,
                    subtitle: null,
                    description: '## A taste of the final visionary video \n\n The final video really worked well with the full portfolio of brand elements and completed the encompassing experience that we were asked to help the team with. It was delivered right on schedule and married the initial vision to a more tangible product. Together with the final brochure, it really left a strong impression long after the presentation for the new venture was delivered.',
                    bulletPoints: null,
                    align: 'left',
                    topPadding: 'none',
                  },
                },
                bodySection: [
                  {
                    type: 'singleLargeImage',
                    position: 'inline',
                    bodyContent: {
                      variation: 'default',
                      image: {
                        url: null,
                        alt: null,
                        heightRatio: 940 / 2360,
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    images: {
      nextProjectBackground: 'citiesOfTheFuture/workDetailsTheKualaLampurSkyline.jpg',
    },
  },
  jlr: {
    title: 'Designing a luxury brochure to disrupt the Pakistani market',
    name: 'JLR Pakistan',
    description: 'We designed a high-end brochure for a group of talented individuals that were pitching to bring Jaguar Land Rover to Pakistan.',
    linkUrl: 'jlr',
    heroSection: {
      type: 'fannedDocument',
      backgroundColor: '#0d0d0d',
      backgroundShade: 'light',
      pagePositionBarColor: '#c6b894',
      properties: {
        variation: 'default',
        images: [
          {
            url: 'jlr/workDetailsHeroEndQuotation.jpg',
            alt: 'Closing Statements',
            heightRatio: 297 / 210,
          },
          {
            url: 'jlr/workDetailsHeroPakistanMap.jpg',
            alt: 'Pakistan Map',
            heightRatio: 297 / 210,
          },
          {
            url: 'jlr/workDetailsHeroFlagshipLocation.jpg',
            alt: 'Flagship Location',
            heightRatio: 297 / 210,
          },
          {
            url: 'jlr/workDetailsHeroContentsPage.jpg',
            alt: 'Jaguar F-Type',
            heightRatio: 297 / 210,
          },
          {
            url: 'jlr/workDetailsHeroFrontCover.jpg',
            alt: 'Front Cover',
            heightRatio: 297 / 210,
          },
        ],
      },
    },
    projectOverview: {
      backgroundShade: 'dark',
      titleColor: '#c6b894',
      overview: 'The Pakistan luxury vehicle market has grown exponentially over the past decade and there aren’t any signs of it stopping anytime soon. JLR Pakistan wanted to pitch this opportunity to Jaguar Land Rover as they felt they were missing out especially due to the recent successes of BMW, Mercedes and Audi. The team already had a strong presence and a wealth of experience in the wider market and had been refining their proposal over the years. Seeing JLR’s competitors move decisively into the rapidly emerging market, they felt it was time for JLR to also introduce their luxury brand and international presence into Pakistan.',
      role: [
        'Branding',
        'Creative direction',
        'Brochure design',
      ],
      year: '2017',
    },
    projectChallenge: {
      backgroundShade: 'light',
      backgroundColor: null,
      challenge: 'JLR Pakistan already had the structure and the resources in place to launch the business, as well as the basic content for their pitch, but needed a brand identity and brochure that clearly conveyed the lofty ambitions of their proposition. We worked with them over a period of a few months to refine their vision and to translate it into a unique visual presence to be used across their business.',
    },
    projectContent: [
      {
        title: "First impressions",
        contentSection: [
          {
            backgroundColor: '#fbfbfb',
            backgroundImage: null,
            backgroundShade: 'light',
            topPadding: 'medium',
            bottomPadding: 'medium',
            contentBlocks: [
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'Catching feelings',
                    subtitle: 'First impressions of the client and their brand',
                    description: 'Since JLR Pakistan were pitching to Jaguar Land Rover, we felt the most important thing we could do was to get a feel for the luxury brand, their values and the colours they gravitated towards.  Because the team had already done their research into the market and nailed down their business proposition, we mainly focused on finding a visual identity for the proposition as well as a creative direction for the project. \n\n We did this by looking at a multitude of the brand touchpoints that potential customers have with Jaguar Land Rover across a variety of mediums, including digital experiences, marketing brochures as well as the physical cars themselves.',
                    bulletPoints: null,
                    align: 'right',
                    topPadding: 'none',
                  },
                },
                bodySection: [
                  {
                    type: 'imageBoard',
                    position: 'inline',
                    bodyContent: {
                      variation: 'twoOnOne',
                      content: [
                        {
                          type: 'image',
                          details: {
                            url: 'jlr/workDetailsResearchCarOutside.jpg',
                            alt: 'The outside of a Jaguar',
                            heightRatio: 700 / 1260,
                          },
                        },
                        {
                          type: 'image',
                          details: {
                            url: 'jlr/workDetailsResearchCarInside.jpg',
                            alt: 'The inside of a Jaguar',
                            heightRatio: 500 / 1040,
                          },
                        },
                        {
                          type: 'image',
                          details: {
                            url: 'jlr/workDetailsResearchDocument.jpg',
                            alt: 'Page layout from a JLR marketing brochure',
                            heightRatio: 600 / 950,
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: "The basics",
        contentSection: [
          {
            backgroundColor: '#ffffff',
            backgroundImage: null,
            backgroundShade: 'light',
            topPadding: 'medium',
            bottomPadding: 'medium',
            contentBlocks: [
              {
                titleSection: {
                  type: 'threeColumn',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'Isn’t it luxurious',
                    subtitle: 'Focusing on the type hierarchies over everything else',
                    description: "The brochure would be the lingering reminder to Jaguar Land Rover of the team’s business opportunity after the pitch itself, so getting the layout and the typefaces working correctly was of utmost importance. We arrived on a refined serif typeface, Chapaza, for the main headings and points of interest, to bring through that element of luxury within the business proposition, and paired it with a sans-serif typeface, Lato, for the main body of the content. This gave us a good balance between making the large amount of information, that made up the majority of the brochure, very readable, whilst still linking the brand with that of Jaguar Land Rover. \n\n We arrived on a generally neutral colour scheme for the brochure to reflect the vision for the business. There was a lovely gold we’d noticed that was quite prevalent in the interiors of the cars, and we decided that this shade would work wonderfully if used sparingly throughout the brochure to draw the readers’ eyes to the areas we wished to highlight.",
                    topPadding: 'none',
                  },
                },
                bodySection: [
                  {
                    type: 'brandBoard',
                    position: 'inline',
                    bodyContent: {
                      variation: 'jlr',
                      sectionContent: {
                        brandColors: [
                          {
                            hexCode: '#181719',
                            shade: 'dark',
                          },
                          {
                            hexCode: '#645c54',
                            shade: 'dark',
                          },
                          {
                            hexCode: '#cec4b3',
                            shade: 'light',
                          },
                        ],
                        titleType: {
                          font: 'chapaza',
                          weights: [
                            'regular',
                            'bold',
                            'italic',
                            'boldItalic',
                          ],
                          exampleText: "Pakistan's stock market has risen faster than any other in Asia over the past 12 months, by a heady 50%.",
                        },
                        bodyType: {
                          font: 'lato',
                          exampleText: "Over the past decade the Pakistan motor vehicle market has grown exponentially, and it continues to grow. Following the recent success of BMW, Mercedes, and Audi we have identified a significant demand from luxury car buyers in Pakistan. Our proposal, our ambition, our desire is very simple. We want to properly bring Jaguar Land Rover to the market and for it to beome the number one luxury vehicle brand in Pakistan.",
                        },
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            backgroundColor: '#fbfbfb',
            backgroundImage: null,
            backgroundShade: 'light',
            topPadding: 'medium',
            bottomPadding: 'small',
            contentBlocks: [
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'Adapting the pitch',
                    subtitle: 'Ducking and diving through a wealth of information',
                    description: "The main priorities for the brochure was to pass on all the detailed information about the business opportunity to readers in a way that invited them to want to know more. We started by immersing ourselves within the intricacies of the provided content so that we would have a solid foundation from which to progress, so we could produce a truly representative end-product.",
                    bulletPoints: null,
                    align: 'right',
                    topPadding: 'none',
                  },
                },
                bodySection: [
                  {
                    type: 'fullBleedImage',
                    position: 'inline',
                    bodyContent: {
                      variation: 'inline',
                      image: {
                        url: 'jlr/workDetailsProvidedContentSlides.png',
                        alt: 'The content we were given to work with at the start of the project',
                        heightRatio: 1884 / 2560,
                      },
                      topMargin: null,
                      bottomMargin: null,
                    },
                  },
                ],
              },
            ],
          },
          {
            backgroundColor: '#101010',
            backgroundImage: null,
            backgroundShade: 'dark',
            topPadding: 'none',
            bottomPadding: 'none',
            contentBlocks: [
              {
                titleSection: null,
                bodySection: [
                  {
                    type: 'fullBleedImage',
                    position: 'inline',
                    bodyContent: {
                      variation: 'parallax',
                      image: {
                        url: 'jlr/workDetailsTheViewOfAJaguar.jpg',
                        alt: 'A view from inside a Jaguar XJ 50 to set the scene for the project',
                        heightRatio: 1800 / 2560,
                      },
                      topMargin: null,
                      bottomMargin: null,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: "Our system",
        contentSection: [
          {
            backgroundColor: '#161616',
            backgroundImage: null,
            backgroundShade: 'dark',
            topPadding: 'medium',
            bottomPadding: 'medium',
            contentBlocks: [
              {
                titleSection: {
                  type: 'threeColumn',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'The key is consistency',
                    subtitle: 'Laying out a flexible content system for the brochure',
                    description: "Surprisingly for a project like this, we felt that the best way to convey all our ideas on the relationships between the typefaces and the content spacings for the brochure would be to start by designing the front cover of the brochure. We had quite a strong idea for the route we wanted to go down, and it didn’t take much tweaking to agree on a single cohesive layout that we wanted to form the basis for the entire document. The final guidelines we developed allowed enough flexibility for each page to best represent their own content without compromising on the integrity of the document as a single entity.",
                    topPadding: 'none',
                  },
                },
                bodySection: [
                  {
                    type: 'singleLargeImage',
                    position: 'inline',
                    bodyContent: {
                      variation: 'default',
                      image: {
                        url: 'jlr/workDetailsFlexibleContentSystem.jpg',
                        alt: 'The brochure guidelines laid out for the entire document to be used to build out the designs',
                        heightRatio: 940 / 2360,
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            backgroundColor: '#101010',
            backgroundImage: null,
            backgroundShade: 'dark',
            topPadding: 'medium',
            bottomPadding: 'medium',
            contentBlocks: [
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'Bringing it all together',
                    subtitle: "Building a story and fitting it into the brochure's design",
                    description: "The best way to pitch the opportunity was to build a clear narrative that told the story of the project from beginning to end. We did this by going through the content provided and laid out areas that we felt were related and those that worked well together onto general page layouts. \n\n We then integrated these into a greyscale wireframed brochure to ensure we focused on getting the correct balance between the brochure’s content and its typography and whitespace.",
                    bulletPoints: null,
                    align: 'right',
                    topPadding: 'none',
                  },
                },
                bodySection: [
                  {
                    type: 'overlappingImages',
                    position: 'inline',
                    bodyContent: {
                      variation: 'default',
                      image: [
                        {
                          url: 'jlr/workDetailsWireframedBrochureSpread.jpg',
                          alt: 'The wireframe for the Pakistan location and stats page spread',
                          heightRatio: 297 / 420,
                        },
                        {
                          url: 'jlr/workDetailsIntroductionSlide.jpg',
                          alt: 'The introduction slide provided by the client for the project',
                          heightRatio: 214 / 380,
                        },
                        {
                          url: 'jlr/workDetailsMarketDealerSlide.jpg',
                          alt: 'The market/dealer overview slide provided by the client',
                          heightRatio: 214 / 380,
                        },
                      ],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: "Brochure design",
        contentSection: [
          {
            backgroundColor: '#161616',
            backgroundImage: null,
            backgroundShade: 'dark',
            topPadding: 'medium',
            bottomPadding: 'medium',
            contentBlocks: [
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'An injection of class',
                    subtitle: 'Making sure the brands personality comes across',
                    description: "The results speak for themselves. We feel that the emphasis we placed on the content and how it worked on the page really paid dividends in the end. The introduction of colour only served to enhance the messages behind each page and helped naturally progress the brochure into its final form.",
                    bulletPoints: null,
                    align: 'left',
                    topPadding: 'none',
                  },
                },
                bodySection: [
                  {
                    type: 'imageRevealSlider',
                    position: 'inline',
                    bodyContent: {
                      variation: 'default',
                      leftImage: {
                        title: 'Wireframed Layouts',
                        image: {
                          url: 'jlr/workDetailsRevealSliderPreviousImage.jpg',
                          alt: 'Some of the wireframed layouts that we worked on from our initial sketches',
                          heightRatio: 1200 / 2360,
                        },
                      },
                      rightImage: {
                        title: 'Final Deliverables',
                        image: {
                          url: 'jlr/workDetailsRevealSliderFinalDeliverables.jpg',
                          alt: 'Our final designed layouts from our wireframed spreads',
                          heightRatio: 1200 / 2360,
                        },
                      },
                      backgroundColor: '#1c1c1c',
                    },
                  },
                ],
              },
            ],
          },
          {
            backgroundColor: '#fbfbfb',
            backgroundImage: null,
            backgroundShade: 'light',
            topPadding: 'none',
            bottomPadding: 'none',
            contentBlocks: [
              {
                titleSection: null,
                bodySection: [
                  {
                    type: 'fullBleedImage',
                    position: 'inline',
                    bodyContent: {
                      variation: 'parallax',
                      image: {
                        url: 'jlr/workDetailsOurDocumentDesignSpread.jpg',
                        alt: 'Our document design spreads and how they progressed from a few of our initial sketches',
                        heightRatio: 1800 / 2560,
                      },
                      topMargin: null,
                      bottomMargin: null,
                    },
                  },
                ],
              },
            ],
          },
          {
            backgroundColor: '#101010',
            backgroundImage: null,
            backgroundShade: 'dark',
            topPadding: 'medium',
            bottomPadding: 'none',
            contentBlocks: [
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'Getting pulses racing',
                    subtitle: "We know what you're here for",
                    description: "Each spread tells its own story, and the unique components and figures interspaced throughout the brochure help to break up the layouts to make themmore interesting for the reader. We used muted colours where needed to fit the aesthetic of the brochure. It's ok to stare.",
                    bulletPoints: null,
                    align: 'right',
                    topPadding: 'none',
                  },
                },
                bodySection: [
                  {
                    type: 'steppedLayout',
                    position: 'inline',
                    bodyContent: {
                      variation: 'singleImage',
                      listItems: [
                        {
                          url: 'jlr/workDetailsPakistanCompetitionMapSpread.png',
                          alt: 'The Pakistan competition location map',
                          heightRatio: 816 / 1060,
                          imageOffset: '-3.773584905%',
                        },
                        {
                          url: 'jlr/workDetailsCompetitorLocationInformationSpread.png',
                          alt: "The local market competitor's information spread",
                          heightRatio: 816 / 1060,
                          imageOffset: '3.773584905%',
                        },
                        {
                          url: 'jlr/workDetailsAutomotiveOverviewSpread.png',
                          alt: 'The automotive overview spread',
                          heightRatio: 816 / 1060,
                          imageOffset: '5%',
                        },
                      ],
                      backgroundImage: {
                        url: 'jlr/workDetailsMapOutlineBack.png',
                        alt: 'The outline of Pakistan as seen from above',
                        heightRatio: 2333 / 2560,
                      },
                      backgroundColor: '#191919',
                    },
                  },
                  {
                    type: 'quotation',
                    position: 'inline',
                    bodyContent: {
                      variation: 'default',
                      content: {
                        quotationMarkColor: '#eddcb7',
                        personLineColor: '#cdbe9e',
                        name: 'Sahal Quazi',
                        title: 'JLR Pakistan',
                        quotation: 'QQore is a full-service digital studio with a small but talented team that create delightful digital products, brands and experiences. With a unique approach guided by thoughtful design, we deliver identities and systems that thrill users and generate business results.',
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: "Final article",
        contentSection: [
          {
            backgroundColor: '#161616',
            backgroundImage: null,
            backgroundShade: 'dark',
            topPadding: 'medium',
            bottomPadding: 'medium',
            contentBlocks: [
              {
                titleSection: {
                  type: 'default',
                  position: 'inline',
                  titleContent: {
                    variation: 'default',
                    title: 'Worth a thousand words',
                    subtitle: 'Need we say more?',
                    description: "We were really happy with how the brochure turned out, and the JLR Pakistan team were delighted with how the final brochure really reinforced the messages they wanted to communicate within their pitch.",
                    bulletPoints: null,
                    align: 'left',
                    topPadding: 'none',
                  },
                },
                bodySection: [
                  {
                    type: 'singleLargeImage',
                    position: 'inline',
                    bodyContent: {
                      variation: 'default',
                      image: {
                        url: 'jlr/workDetailsFinalBrochure.jpg',
                        alt: 'The final brochure',
                        heightRatio: 1200 / 2360,
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    images: null,
  },
};

Object.keys(projects).map((projectName, index) => {
  const images = {
    logoDark: projectName + '/serviceLogoDark.png',
    logoLight: projectName + '/serviceLogoLight.png',
    home: projectName + '/serviceImage.png',
    logomarkBackground: projectName + '/logomarkBackground.jpg',
    nextProjectBackground: projectName + '/nextProjectBackground.jpg',
    detailsHeroBackground: projectName + '/workDetailsHeroBackground.png',
    detailsHeroFeaturedBackground: projectName + '/workDetailsHeroFeaturedBackground.jpg',
  } as IProjectImages;

  projects[projectName].images = Object.assign(images, projects[projectName].images);
});

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export enum keys {
  SHOW_LOADING_SCREEN = 'SHOW_LOADING_SCREEN',
  SHOW_LOADINGG_SCREEN = 'SHOW_LOADINGG_SCREEN',
}

interface IDefaultAction {
  type: keys.SHOW_LOADING_SCREEN;
}

interface IAnotherDefaultAction {
  type: keys.SHOW_LOADINGG_SCREEN;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type IKnownAction = IDefaultAction | IAnotherDefaultAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
// We are using them to fire off our events that will be handled by the root sagas and delegated consequently

export const actionCreators = {
  //intialiseNewPage: () => (dispatch) => {
  //  dispatch({ type: 'INITIALISE_PAGE_COMPONENTS' });
  //},
};

// ----------------
// SAGA WATCHERS - Register all saga watchers here that will intercept all dispatched calls and delegate appropriately

// ----------------
// SAGA WORKERS - These are saga worker functions that are called when receiving saga dispatches. The saga watchers
// intercept dispatched calls and call the relevant saga functions when appropriate

// ----------------
// ROOT SAGA - Register all saga watchers into one root saga to be initialised in configureStore

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<IProjectsState> = (state: IProjectsState, action: IKnownAction) => {
  switch (action.type) {
    case keys.SHOW_LOADING_SCREEN:
      return {
        ...state,
      };
    case keys.SHOW_LOADINGG_SCREEN:
      return {
        ...state,
      };
    default:
      // The following line guarantees that every action in the KnownAction union has been covered by a case above
      const exhaustiveCheck: never = action;
  }

  // For unrecognized actions (or in cases where actions have no effect), must return the existing state
  //  (or default initial state if none was supplied)
  return state || {
    projectDetails: projects,
  };
};

// ----------------
// SELECTORS - These are functions exposed to UI components that will give them access to the associated store components.
// They only return the reference to the required state in the store, they don't change it.

const projectsSelector = (state: IApplicationState) => state.projects;
export const getProjects = createSelector(
  [projectsSelector],
  projectsObject => {
    //console.log("Output selector running: getProjects");
    return projectsObject;
  },
);

export const getProjectDetails = createSelector(
  [getProjects],
  projectsObject => {
    //console.log("Output selector running: getProjectDetails");
    return projectsObject.projectDetails;
  },
);

export const makeGetProject = () => createSelector(
  projectsSelector,
  (_, projectId) => projectId,
  (allProjects, projectId) => {
    //console.log("Output selector running: makeGetProject");
    return allProjects.projectDetails.hasOwnProperty(projectId) ? allProjects.projectDetails[projectId] : null;
  },
);

export interface INextProjectDetail {
  title: string;
  nextBackgroundImage: string;
  linkUrl: string;
}

export interface INextProjectDetails {
  [id: string]: INextProjectDetail;
}

export const getNextProjectDetails = createSelector(
  [getProjectDetails],
  projectDetails => {
    const nextProjects: INextProjectDetails = {};
    Object.keys(projectDetails).map((projectName, index) => {
      nextProjects[projectName] = {
        title: projectDetails[projectName].title,
        nextBackgroundImage: projectDetails[projectName].images.nextProjectBackground,
        linkUrl: projectDetails[projectName].linkUrl,
      };
    });

    return nextProjects;
  },
);

export const getProjectLogomarkBackgrounds = createSelector(
  [getProjectDetails],
  projectDetails => {
    //console.log("Output selector running: getProjectLogomarkBackgrounds");

    const logomarkBackgrounds: string[] = [];
    Object.keys(projectDetails).map((projectName, index) => {
      logomarkBackgrounds.push(projectDetails[projectName].images.logomarkBackground);
    });

    return logomarkBackgrounds;
  },
);
