import React, { useState, useCallback, useEffect, useRef, useLayoutEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as fromUserInterface from 'store/UserInterface';

import { useSpring, a, SpringConfig } from 'react-spring';

import Logomark from './Logomark/Logomark';

import { useLatest, getPixelRatio } from 'utils/Index';

interface IOwnProps {
  id: string;
}

type IProps = IOwnProps & fromUserInterface.ILoadingScreen;

const Screen: React.FC<IProps> = ({ id, visible, logomarkBackground, ...props }) => {
  const interfaceInitialised = useSelector(fromUserInterface.getInitialised);
  const loadingScreens = useSelector(fromUserInterface.getLoadingScreens);
  const dispatch = useDispatch();
  const hideLoadingScreen = useCallback((screenId: string, modifyScroll: boolean) => dispatch(fromUserInterface.actionCreators.hideLoadingScreen(screenId, modifyScroll)), [dispatch]);
  const removeLoadingScreen = useCallback((screenId: string) => dispatch(fromUserInterface.actionCreators.removeLoadingScreen(screenId)), [dispatch]);
  const animateInPage = useCallback((pageId: string) => dispatch(fromUserInterface.actionCreators.animateInPage(pageId)), [dispatch]);

  const latestLoadingScreens = useLatest(loadingScreens);

  const [animatedIn, setAnimatedIn] = useState(false);
  const [animateLogomark, setAnimateLogomark] = useState(true);
  const [endLogomarkAnimation, setEndLogomarkAnimation] = useState(false);

  // 1. Animate in the loading screen on component mount
  const [animateSpring, setAnimateSpring] = useSpring(() => ({
    y: 0,
    from: { y: 1 },
    onChange: {
      y: value => onChange(value),
    },
    immediate: !interfaceInitialised, // If the interface hasn't been initialised it means its a new hard load from the server, so we want to show the loading screen immediately
  }));

  // 2. This triggers the logomark to animate out which will then trigger the screen to animate out once it's finished
  useEffect(() => {
    if (animatedIn && !visible) {
      setEndLogomarkAnimation(true);
    }
  }, [animatedIn, visible]);

  // 3. Trigger the loading screen to animate out from the logomark itself
  const hideLoadingScreenFromLogomark = () => {
    setAnimateSpring({ y: -1, immediate: false });
    // console.log(latestLoadingScreens.current);

    // This will prevent animating pages in before last loading screen has animated out
    if (Object.keys(latestLoadingScreens.current).length === 1)
      animateInPage(Object.keys(latestLoadingScreens.current)[0]);
  };

  //  If the screen has been animated in mark it as animated in, if the loading screen has been animated out then remove this loading screen from the store completely
  const onChange = value => {
    if (value >= 0 && value < 0.000001)
      setAnimatedIn(true);
    else if (value >= -1 && value < -0.999999)
      removeLoadingScreen(id);
  };

  // This will run once the logomark has completely animated out and remove the component from the interface
  const endedLogomarkAnimation = () => {
    setAnimateLogomark(false);
    setEndLogomarkAnimation(false);
  };

  const screenStyles = {
    transform: animateSpring.y.to(y => `translateY(${100 * y}vh)`),
  };

  const logomarkProps = {
    finalBackgroundImage: logomarkBackground,
    endLogomarkAnimation,
    endedLogomarkAnimation,
    hideLoadingScreen: hideLoadingScreenFromLogomark,
  };

  const logomarkWrapperStyles = {
    display: animateLogomark ? 'block' : 'none',
  };

  return (
    <Fragment>
      {/* <canvas className='screen' ref={canvasRef} /> */}
      <a.div className='screen' style={screenStyles} />
      <div className='logomarkWrapper' style={logomarkWrapperStyles}>
        {(animateLogomark ? <Logomark {...logomarkProps} /> : null)}
      </div>
    </Fragment>
  );
};

export default Screen;
